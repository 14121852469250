<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="What Students Receive"
            highlight="Students Receive"
        />
        <p>We define content as any curriculum, instructional or learning material that is placed in front of a student. As an organization that aims to improve student outcomes, we look to create content that better prepares them for the future. It's not just about test scores and how well they do assignments. It's also about <span class="text-focus">content relevancy and how much the material that's placed in front of them aligns with their journey going forward, into college and beyond</span>. There is much complexity to this as we understand the need to stay aligned with guidelines and regulations to the curriculum framework within the system. In addition, we need to remember the importance of not burying foundational learning requirements such as math, writing, reading and others.</p>
    </div>
    <OfferBanner
        offer="Content"
        icon="Icons/V4/OurFocus/ProductsAndServices/Content.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/ContentWatermark.png"
        :description="['<i>Any curriculum, instructional or learning material that is placed in front of a student</i>']"
        :height="410"
    />
    <div class="wide-content">
        <h2>Guidelines Restricting Learning</h2>
        <p>Also due to systemic governance and bureaucracy, there are boundaries that sit between the public school sector and the external sources that provide solutions to students. There are district Admins, Boards of Education and a Presidency that dictate educational operations including defining the content standards such as the <router-link to="/OurFocus/Education/ContentStandards">Common Core State Standards (CCSS)</router-link>. With that, comes restrictions and guidelines that must be followed for every curriculum, teacher and student. <span class="text-focus">We feel that this is a major obstacle to educational progress as it shackles our students to a parochial content-set</span>. We need more flexibility to be creative in terms of content creation. The concept of the "Common" Core alone sounds abstract since it is a blanketed solution to an entire nation of learners that all learn differently. Even a classroom of twenty needs differentiation.</p>
        <h2>A Stagnant System</h2>
        <p>The system as it currently stands, is based on old-fashioned thinking and still wants to produce factory workers. We have to understand that the student body is an extremely dynamic population and therefore we must learn to produce dynamic solutions and evolve with the student. <i>The <a href="https://www.edweek.org/policy-politics/no-child-left-behind-an-overview/2015/04" target="_blank">No Child Left Behind Act</a></i>*, which introduced an overhaul of school testing and aimed to create an accountability for schools for improving scores, created a system in which very little innovation in teaching and learning are possible.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "ContentFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [ 
            '<h2>Research & Understanding</h2>',
            '<p>This is a large area to cover and can come in many forms of deliverables and containing a plethora of subjects and subject areas. We must adhere to any restrictions and guidelines as set forth by the educational establishment in which we are working unless we are implementing independently from the system. Keeping in mind the guidelines, there are a number of approaches to take when considering the different types of content.</p>',
            '<p>Researching and understanding the existing educational landscape and the established content that exists today will be key. In addition, understanding the scope and scale in which the content reaches students and for which grade levels is equally important. The content framework may vary from school to school but there are district-level and even nation-level frameworks in place that will detail the guidelines and types of subjects being taught. As an organization interested in creating content that benefits students, we will be studying and analyzing the subject areas being taught today and the expected results from the performing student body. To refine our research in this area, we will be harnessing partnerships, utilize the <a href="/Educators">Teacher Empowerment Circle (TEC)</a>, the <a href="https://www.cde.ca.gov" target="_blank">California Department of Education</a> (for local data) and other sources to best understand the content landscape in our schools.</p>',
            '<p>Using this ongoing research, we will be able to better position ourselves to create more effective materials that are generated from data and analysis. In addition, due to the close relationship with our schools and their students\' lessons, the opportunities for content-collaboration in which we combine efforts to produce additional content, we are are in a position to do so more seamlessly.</p>',
            '<h2>Content Task Force (CTF)</h2>',
            '<p>As a secondary branch-off of our research initiatives, we are looking to establish a <span class="text-focus">Content Task Force</span> which would consist of experts on the subject of education, schooling and best standard practices for subject deliverance in the classroom. A Content Task Force expert provides valuable information about the current subject body being taught and evolving or devolving patterns that exist in various subjects over the course of a specified timeframe (likely years, even decades).</p>',
            '<p>More importantly, to either validate or contrast against the research found in this area, another major component of analysis will be focused on discovering and defining content relevancy. The term relevancy carries many definitions here; relevance in the form of <span class="text-focus">culture-aware materials for the diverse student and classroom</span>, relevance in <span class="text-focus">tailoring materials to student aspiration and intrigue</span>, relevance in the form of <span class="text-focus">how well the material aligns to the student and their post-schooling lives in relation to the world we live in today (survivability)</span>. This body of researchers would exist on a continuum to accommodate for the ever-evolving learning landscape.</p>',
            '<h2>Structure</h2>',
            '<p>Structure refers to the method and approach to delivering content. To support the mission of improving learning experiences and the overall classroom, we also need to look at how content is presented, in what formats and other capacities.</p>',
            '<p><span class="text-focus">Being present in the classroom helps us to understand structure</span> and the more we continue to reside in the system, the more we are able to identify instructional patterns. We aim to remain cognizant of these methods, the ratio of instructor-led to lab-style lessons, or environments that enable hands-on and kinesthetic learning.</p>',
            '<p>We understand that quality content can be negated by an unbalanced structure and that the opposite can also be true.</p>'
        ];
    }
};
</script>