<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Research"
        copy="Relying on data to guide and direct us to better solutions while validating the need and incentive be innovative in our approach."
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
export default {
    name: "BodyIntroductionEducation",
    components: { BodyIntroduction },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Research",
            source: "Research.png",
            width: 130,
            height: 130
        };
    }
};
</script>