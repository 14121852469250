<template>
    <div v-if="area === 'Products & Services'">
        <div class="cards flex flex-wrap">
            <ProductsAndServices
                v-for="(progress, p) in PROGRESS.Sources.Schools"
                :key="p"
                :data="progress"
                :index="p"
            />
        </div>
    </div>
    <div v-if="area === 'Student Outcomes'">
        <StudentOutcomes/>
    </div>
    <div v-if="area === 'School Climate'">
        <SchoolClimate/>
    </div>
</template>

<script>
import { PROGRESS } from "@/assets/JS/Progress/Progress.js";
import ProductsAndServices from "@/components/Progress/Schools/Areas/ProductsAndServices.vue";
import StudentOutcomes from "@/components/Progress/Schools/Areas/StudentOutcomes.vue";
import SchoolClimate from "@/components/Progress/Schools/Areas/SchoolClimate.vue";
export default {
    name: "ProgressAreas",
    props: { area: String },
    components: {
        ProductsAndServices,
        StudentOutcomes,
        SchoolClimate
    },
    data: () => ({ PROGRESS })
};
</script>