<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Education"
        copy="This encompasses the infrastructure and reform of the system of education. In order to solve educational problems, we must approach at system-level."
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
export default {
    name: "BodyIntroductionEducation",
    components: { BodyIntroduction },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Education",
            source: "Education.png",
            width: 130,
            height: 130
        };
    }
};
</script>