<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="A Growing Crisis"
            highlight="A Growing Crisis"
        />
        <p>Much of a student's well-being determines both the quality of their work in the classroom as well as their desire to receive it. There is a major crisis with mental health in our nation and understanding the causes and remedies is a large task. Some of that is due to the stigma regarding having conversations of this topic and how to seek proper treatment; this can deter individuals from having an open dialog about it.</p>
    </div>
    <OfferBanner
        offer="Student Mental Health"
        icon="Icons/V4/OurFocus/Education/StudentMentalHealth.png"
        watermark="Icons/V4/OurFocus/Education/StudentMentalHealthWatermark.png"
        :description="['<i>Good mental health is a pre-requisite to performing academically and in life</i>']"
        :height="380"
    />
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "StudentMentalHealthFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [ 
            '<h2>Research & Understanding</h2>',
            '<p>Due to the complexity and stigma surrounding mental health, for the time being, we are merely positioning ourselves in a way to be a resource for studying and understanding the topics and terminology of mental health. We are not equipped to treat or diagnose issues but we are equipped to be cognizant of its presence in the academic landscape and the roles establishments play in handling these issues. We are here to make sure students succeed and we understand that <span class="text-focus">good mental health is a pre-requisite to functioning well acedemically</span>.</p>',
            '<p>We will continue to research the topic and that includes ongoing discussions with schools to determine their role in handling student mental health issues as well as understanding what measures are currently in place. This will help us refine a dialog on the matter so that we can work on communicating with other expert sources and begin being a liaison or conduit for these types of services.</p>'
        ];
    }
};
</script>