<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Our Focus"
        copy="What we are looking at and how we plan to get there. A roadmap to greater solutions; <b><i>Education</i></b>, <b><i>Products &amp; Services</i></b> and our <b><i>Partners</i></b>."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Taking Action"
            highlight="Action"
        />
        <p>We break down our focus and strategies into three main categories that we feel encompass the work we are doing; <span class="text-focus">Education</span>, <span class="text-focus">Products &amp; Services</span> and <span class="text-focus">Partners</span>. By approaching our mission through the lens of these three categories, we are able to create a better learning experience.</p>
        <p>We understand there are many moving parts to any body of education, its functions, its teachers and its students. We will be studying the many corners that make up the academic landscape and support our findings and solutions with continued data collection and analysis. These are just a few of our approaches as we plan to evolve with our customers.</p>
    </div>
    <FocusCategories/>
    <StrategicPlanSourceReferenceBanner/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import FocusCategories from "@/components/FocusCategories";
import StrategicPlanSourceReferenceBanner from "@/components/Banners/SourceReference/StrategicPlan.vue";
export default {
    name: "OurFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        FocusCategories,
        StrategicPlanSourceReferenceBanner
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Our Focus",
            source: "OurFocus.png",
            width: 130,
            height: 130
        };
    }
};
</script>