var $;
var MASTER;
var ASSETS = {
    Construct: function(jquery, master) {
        $ = jquery;
        MASTER = master;
        MASTER.Construct($);
        this.ScrollProperties();
    },
    ScrollProperties: function() {
        const $headerTop = $("#header #top");
        const $logo = $headerTop.find("img");
        $(window).on("scroll", function () {
            if (!MASTER.Mobile()) {
                if ($("html").scrollTop() >= 300) {

                    // Fix header to the top
                    $headerTop.css({
                        "position": "fixed",
                        "width": "100%",
                        "z-index": 500,
                        "border-bottom": "1px solid #fff"
                    });
    
                    // Animate scale of logo
                    $logo.stop().animate({
                        width: "220"
                    }, 250);
    
                } else {
    
                    // Reset header position when up high enough on the page
                    $headerTop.css({
                        "position": "inherit",
                        "width": "auto",
                        "border-bottom": "none"
                    });
    
                    // Animate scale of logo
                    $logo.stop().animate({
                        width: "320"
                    }, 250);
    
                }
            }
        });
    },
    ShowCarousel: function(route, config) {
        let n = 0;
        for (const nonCarouselRoute in config.noCarouselRoutes) {
            if (route.fullPath.indexOf(config.noCarouselRoutes[nonCarouselRoute]) !== -1) {
                n++;
            }
        }
        return n > 0;
    }
};

module.exports = { ASSETS };