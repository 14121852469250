<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="A Complex Population"
            highlight="Population"
        />
        <p>Classroom size is dependent on staff availability and the student-to-teacher ratio within a school system. For example, Los Angeles, California's <a href="https://www.lausd.org/domain/4" target="_blank">Los Angeles Unified School District (LAUSD)</a>, the second largest school district in the nation, has an abundance of students which makes it harder to shrink the classroom size without hiring more teachers (made more difficult by the teacher shortage).</p>
    </div>
    <OfferBanner
        offer="Classroom Size"
        icon="Icons/V4/OurFocus/Education/ClassroomSize.png"
        watermark="Icons/V4/OurFocus/Education/ClassroomSizeWatermark.png"
        :description="['<i>Population is a major hurdle in educational progress</i>']"
        :height="380"
    />
    <div class="wide-content">
        <p>With too many students in one classroom, we are more likely to strip students of their right to share their voice, to be part of the conversation and be on the receiving end of a responsive curriculum or responsive teacher. Without smaller classroom sizes, <span class="text-focus">we are risking exclusion in a place where we must produce more inclusion</span>.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "ClassroomSizeFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [ 
            '<h2>Research & Understanding</h2>',
            '<p>Unless we are working independently from the system, classroom size is a challenging metric to control. However, we can continue to observe what different classroom cultures look like, tracking metrics such as <span class="text-focus">behavior</span>, <span class="text-focus">attentiviness</span>, <span class="text-focus">learning growth</span>, <span class="text-focus">student-to-teacher ratios</span> and how this applies to the quantity of students and their levels of progression. <span class="text-focus">Population is a major obstacle to many of our educational issues</span> but we still need to find methods of creating programming that can accommodate for large and small learning settings.</p>'
        ];
    }
};
</script>