<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Tracking Student Progress"
            highlight="Student Progress"
        />
        <p>Much of the approaches to measuring progress today involves a look at the often-utilized summative assessments such as quizzes, tests and report cards but what might be missing from these is the in-between; more of the formative assessments. They do come in many forms in our schools but we are not relying enough on them to track learning growth. The issue with relying heavily on testing is that they do not always convince us of a student's mastery in a certain subject. What they can tell us, is if a student has memorized key parts of an assignment or topic. However, <span class="text-focus">memorization does not equate to mastery — nor does it reflect actual learning</span>.</p>
    </div>
    <OfferBanner
        offer="Assessments & Measuring Progress"
        icon="Icons/V4/OurFocus/Education/MeasuringProgress.png"
        watermark="Icons/V4/OurFocus/Education/MeasuringProgressWatermark.png"
        :description="['<i>Tracking student outcomes and well-being in real-time</i>']"
        :height="380"
    />
    <div class="wide-content">
        <h2>Science Versus Myth</h2>
        <p>The modern system has become fixated on defining the process of learning by inquiring of a student's knowledge through questions that check for understanding through a metric of memorization. These types of questions determine a certain level of proficiency in a subject or topic and the mastery is assumed if memorized but <span class="text-focus">this doesn't necessarily translate to student progression in learning the key components of the subjects</span>. Again, memorization does not equate to proficiency nor does it track the process of learning. This approach is far too common in modern education; <span class="text-focus">basing learning growth off a belief system rather than of a science</span>.</p>
        <p>Every day, we rely on science in the medical industry. We use science to create, test and prescribe medicines. We rely on the same science to perform operations during medical procedures but we don't do this in education. By implementing a science-based approach to teaching, we can then begin to really track progress by defining learning through the "<span class="text-focus">science of learning</span>" or "<span class="text-focus">the technology of teaching</span>". By applying the science, learning can be defined as a change in behavior over time. We can't detect this change of behavior over time if we continue to ask the same summative questions surrounding a "belief" in the outcomes.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
    <SourceReferenceBanner
        source="Performance Benchmark"
        description="A <span class='blue-i'>Model</span> that utilizes current educational scoring data sources to gather metrics regarding student performance in academia while <span class='blue-i'>incorporating our own data collection and methods of measuring progress</span>. This Model aims to meet a school and its student(s) at the greatest point of their current achievement score in all subjects and areas, combining their performance with ours to <span class='blue-i'>define a Benchmark that represents desired goals and outcomes</span>."
        backgroundColor="#fff"
        height="auto"
        :url="{
            text: 'View Concept',
            destination: 'https://believeinyou.org/FFE/Documents/Performance Benchmark.pdf?v=2',
            newWindow: true
        }"
        iconSource="Icons/V4/ConceptSource.png"
        watermark="Icons/V4/ConceptSourceWatermark.png"
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "MeasuringProgressFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        SourceReferenceBanner
    },
    data: () => ({ conclusionBanner: [] }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Research</h2>',
            '<p>There are an endless array of methods for tracking student progress and all vary in effectiveness and outcomes. To better position ourselves for this role, we need to understand the primary solutions for doing so and how formative, summative and other assessments are being conducted in the classroom. <span class="text-focus">Observing how teachers practice data collection is important to developing new approaches and concepts for measuring progress</span>.',
            '<h2>Presence</h2>',
            '<p>Part of our studies involve being in the classroom to gauge best approaches to track growth in learning. This also allows us to more closely examine the data-driven methodologies and their respective outcomes among other in-person assessment strategies.</p>',
            '<h2>Practicing Methods</h2>',
            '<p>The vast number of assessments and reporting on student progress is both good and bad. It\'s good because we have the advantage of comparative analysis from multiple sources and datasets (and from the same subject) but bad because varying outcomes from the same source or metric alludes to in-authenticity. As an independent approach, we want to design and model our own concept for tracking progress using the science of learning (through the technology of teaching) <i>Blind Spots</i>*. Using our relationships with educational establishments, we can align our tracking model with their current datasets on student performance and we can define our own benchmarks and milestones as a separate source of data that drives our efforts further. This separation can also act as a means to reduce "contamination" among clashing outcome reporting.</p>',
            '<p>One such concept we are working on developing is the <span class="text-focus">Performance Benchmark</span>. This concept relies on taking a snapshot current district datasets regarding student outcomes with our own datasets and defining our own benchmarks and milestones in which we can monitor student progress across various scales for each subject and grade level.</p>'
        ];
    }
};
</script>