<template>
    <a :href="url" target="_blank">
        <img class="icon-social" :src="require(`@/assets/Images/${source}`)" :alt="`Icon - ${type}`" :title="type" :width="width"/>
    </a>
</template>

<script>
export default {
    name: "SocialIcon",
    props: {
        type: String,
        source: String,
        width: String,
        url: String
    }
};
</script>