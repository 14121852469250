<template>
    <div class="topper-watermark-banner" :style="`background-image: url('${source}')`"></div>
</template>

<script>
export default {
    name: "TopperWatermarkBanner",
    props: { watermark: String },
    data: () => ({ source: "" }),
    beforeMount() { this.source = require(`@/assets/Images/${this.watermark}`); }
};
</script>