<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Core Values"
        copy="The best part of seeing others' core functions is watching them glow once they realized we can see them."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="How We See You"
            highlight="You"
        />
        <p>As believers, we act and react through our core values which we practice every day and exude onto our friends, families and customers. These are some of the attributes we strongly believe in.</p>
        <div class="cards flex flex-wrap">
            <Card
                v-for="(coreValue, c) in CoreValues"
                :key="c"
                :data="coreValue"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import CoreValues from "@/assets/JS/Cards/CoreValues.json";
export default {
    name: "CoreValuesView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({
        bodyIntroductionIcon: {},
        CoreValues
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Core Values",
            source: "CoreValues.png",
            width: 130,
            height: 130
        };
    }
};
</script>