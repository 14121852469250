<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="We Celebrate Educators"
            highlight="Celebrate Educators"
        />
        <p>We want to celebrate the work of the incredible teachers in education today. They are the pillars that keep the system in motion and provide our youth with a roadmap for tomorrow.</p>
    </div>
    <OfferBanner
        offer="Teacher Empowerment Circle (TEC)"
        icon="Icons/V4/OurFocus/ProductsAndServices/TEC.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/TECWatermark.png"
        :description="['<i>Creating better solutions through the experts on the inside</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>The <span class="text-focus">Teacher Empowerment Circle (TEC)</span> is the development and initiation of a formation of experts in the industry of education helping to drive the mission, operations and strengthen the integrity of building successful learning environments. The mission of the TEC will be aimed at ensuring we maximize our educators in not only providing their educational know-how to the organization, but spearhead specific areas of research, program execution and provide us insight to <span class="text-focus">what it takes to be successful in today's classroom</span>.</p>
        <p>Part of the work we do to ensure we understand the students we serve, and the system of education itself, revolves around being present within the works of that system. There are already many experts in the industry and we would like to utilize them further to remain connected, receive important information about the academic landscape, create integrity around relationships and further bind the inside of the system to the outside where potential external entities can capitalize on the know-how coming from the classrooms of today.</p>
    </div>
    <ImageBanner
        name="Teacher Empowerment Circle (TEC)"
        image="TEC.png"
        watermark="TECWatermark.png"
        backgroundColor="#f2f7ff"
        :showTopBar="true"
    />
    <div class="wide-content">
        <p>By building this circle and securing these relationships, we can move closer to enhancing the schooling experience with more thought-out approaches to solutions and the development of products and services that are a direct result of data and research from our own educators. <span class="text-focus">As a community, we can be stronger by binding task forces with industry experts and improve the system over time through the power of inclusion</span>.</p>
    </div>
    <SourceReferenceBanner
        source="Teacher Empowerment Circle (TEC)"
        description="The development and initiation of a formation of experts in the industry of education helping to drive the mission, operations and strengthen the integrity of building successful learning environments."
        backgroundColor="#fff"
        height="auto"
        :url="{
            text: 'View Document',
            destination: 'https://believeinyou.org/FFE/Documents/Teacher Empowerment Circle (TEC).pdf?v=2.1',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import ImageBanner from "@/components/Banners/Image.vue";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "TeacherEmpowermentCircleFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        ImageBanner,
        SourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [ 
            '<h2>Using Our Network</h2>',
            '<p>Upon conducting visits to schools, we have established a network of professionals within the industry that fit the mold of the TEC through their expertise and presence within the system. <span class="text-focus">We will be capitalizing on these relationships to strengthen our link to the classrooms and their students</span>.</p>',
            '<p>In addition, these professionals have begun interfacing with the us in regards to information download which has already created a binding that tightens the linkages between our external source and the school\'s internal functions, creating a mutual dialog regarding our efforts.</p>',
            '<h2>Outreach</h2>',
            '<p>To expand the sources within the TEC outside of our current network, we are creating candidate profiles that contain eligible parties as determined by their experiences (or previous experiences) in education. We are developing a communication plan to establish a relationship with these individuals, first introducing them to the mission and identifying their potential roles within the organization and then how they can be extensions of know-how in the industry.</p>',
            '<h2>Role Recognition</h2>',
            '<p>The role of this concept is meant to amplify our reach and position in academics but we want to use this initiative as a channel to recognize the efforts of educators everywhere as they take on the very important role of preparing our youth for the road ahead. We are hoping that this becomes an outlet that more educators feel respected and appreciated for the work that they do and hope that this can <span class="text-focus">solidify teacher retention in the long term as well</span>.</p>'
        ];
    }
};
</script>