<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Pillars of Education Walking Away"
            highlight="Pillars of Education Walking Away"
        />
        <p>We have a teacher shortage crisis in education today and more and more educators are walking away from the profession. Though exacerbated by the pandemic, there are multiple long-standing factors that are causing this movement to occur. Just a few are pay instability, feeling undervalued and reaching a point of burnout. Many teachers are only entering the profession for a short period of time.</p>
    </div>
    <OfferBanner
        offer="Teacher Shortage"
        icon="Icons/V4/OurFocus/Education/TeacherShortage.png"
        watermark="Icons/V4/OurFocus/Education/TeacherShortageWatermark.png"
        :description="['<i>More and more experts are walking away from the profession</i>']"
        :height="380"
    />
    <div class="wide-content">
        <p>Statistics indicate that a third of new teachers leave within three years <i><a href="https://edsource.org" target="_blank">EdSource</a> as of January 2022</i>*. Not only are current teachers contemplating their roles as professionals but there has been a drop in the amount of students wanting to become teachers and many are even being encouraged not to pursue the profession.</p>
        <p>Another major driver of teacher fallout is due to the content standards put in place by governance whether national or state-mandated. An example of this being the <router-link to="/OurFocus/Education/ContentStandards">Common Core State Standards (CCSS)</router-link> and the lack of flexibility that comes with it; teachers are being forced to teach to the test and are stripped of any creativity to do their best work as educators.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "TeacherShortageFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [ 
            '<h2>Engagement</h2>',
            '<p>We feel that the role of the educator is becoming lost among society and that contributors to a teacher shortage is often due to many factors. We want acknowledge and respect the hurdles they face and form relationships with teachers, create programming and events that spotlight and celebrate the important work they are doing. We will use our <a href="/Educators">Teacher Empowerment Circle (TEC)</a> initiative to form a stronger bond between the organization, the teacher and the schools in which they are located.</p>'
        ];
    }
};
</script>