<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="What We Observe"
            highlight="Observe"
        />
        <p>The public sector is governed by many bodies of overseers, policymakers and boards that dictate the flow of education, down to the material being taught and how funds are allocated. The issue with this level of bureaucracy is that the sources charged with determining system functions and its moving parts <span class="text-focus">are not spending enough time in the physical (or virtual) locations in which they are focusing</span>, in-authenticating deliverance of effective policies and guidelines. This leads to the guidelines being more complex than they need to be and oftentimes are based on a belief or a fixation of what should be considered a “standard” and this is rehashed year after year.</p>
    </div>
    <OfferBanner
        offer="Governance &amp; Bureaucracy"
        icon="Icons/V4/OurFocus/Education/Governance.png"
        watermark="Icons/V4/OurFocus/Education/GovernanceWatermark.png"
        :description="['<i>An academic dictatorship</i>']"
        :height="380"
    />
    <div class="wide-content">
        <p>With these standards, there can be ongoing restrictions in both the classroom structure and the materials being taught as well as how they are delivered. These restrictions trickle down to the administrators, eventually landing in the classroom, forcing educators to be bound to a standard that limits flexibility in teaching, <span class="text-focus">which then limits a student's flexibility to learn and practicing creativity</span>.</p>
        <h2>Dictating Standards</h2>
        <p>The district Admins, Boards of Education and Presidency that dictates the entire operation of the system for every student, even defining the <router-link to="/OurFocus/Education/ContentStandards">Common Core State Standards (CCSS)</router-link> curricula, do not spend enough time in the classroom. In order to offer more effective solutions for the classroom, one must be in the classroom. The concept of the Common Core alone sounds abstract since it is a blanketed solution to an entire nation of learners that all learn differently. <span class="text-focus">Even a classroom of twenty needs room for differentiation</span>.</p>
        <p>Currently, the system is based on old-fashioned reasoning and still aims to produce factory workers. We have to understand that the student body is an evolving, dynamic population and therefore we must learn to produce dynamic solutions and be reactive in student approach. The <a href="https://www.edweek.org/policy-politics/no-child-left-behind-an-overview/2015/04" target="_blank">No Child Left Behind Act</a>, a bill which introduced an overhaul of school testing and aimed to make schools accountable for improving scores, created a system that tracks progress solely by memorization, rather than actual learning.</p>
    </div>
    <ConclusionBanner :copy="[
        '<h2>Research &amp; Understanding</h2>',
        '<p>Taking the role of government in public education at face value is essentially mandatory; we have a large, complex academic ecosystem with many, many moving parts. The problem is identifying and understanding all the moving pieces. Though, however complex, <span class=\'text-focus\'>we need to study each component and how it creates movement or stagnation in educational progress</span>. With this information, we can pivot ourselves to a more system-centric approach.</p>',
        '<p>In order to gain control and flexibility over the products and solutions we produce for educational establishments, we need to understand the complexities of transferring our solutions to the public sector so we can master accommodating for their regulatory position and logistics.</p>'
    ]" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "GovernanceFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    }
};
</script>