<template>
    <div class="slide" v-if="showSlide">
        <a :href="slide.URL.Value" :target="windowTarget">
            <img class="image span" :src="image" :alt="`Carousel - ${slide.Title.Value}`" :title="slide.Title.Value"/>
            <div class="details single" :style="styles.copyColor">
                <h2 :style="styles.copyColor">{{ slide.Title.Value }}</h2>
                <div class="bar"></div>
                <p class="description">{{ slide.Description.Value }}</p>
                <p class="author">~{{ slide.Author.Value }}</p>
            </div>
        </a>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    name: "CarouselSlide",
    props: {
        slide: Object,
        index: Number,
        state: Object
    },
    data: () => ({
        showSlide: true,
        image: "",
        styles: { copyColor: "" },
        windowTarget: ""
    }),
    beforeMount() {
        this.showSlide = this.index === 0;
        this.image = require(`@/assets/Images/${this.slide.Images.Desktop}`);
        this.styles = { copyColor: `color: ${this.slide.Title.Color};` };
        this.windowTarget = this.slide.URL.NewWindow ? "_blank" : "_self";
    },
    updated() {
        this.showSlide = this.state.selected === this.index;
        $(".slide").css("opacity", .5);
        $(".slide").stop().animate({
            opacity: 1
        }, 150);
    }
};
</script>