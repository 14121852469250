<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Tracking Learning Growth"
            highlight="Learning Growth"
        />
        <p>In order to determine and validate any effective solutions, we must practice the art of measuring progress among students. Forms such as summative, formative, survey data, program feedback, performance benchmarking, data-driven instruction and much more, will give allow us to be more reactive to student needs and gauge what solutions are working and what needs more tweaking.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Core Idea</h2>
            <p>The key questions that drive effective data-based instruction are:</p>
            <ul>
                <li>How do we know if our students are learning?</li>
                <li>And if they're not, what do we do about it?</li>
            </ul>
            <h2>Interim Assessments</h2>
            <ul>
                <li>Year-end analysis is almost useless since year is past, so interim assessments are more effective and logical</li>
            </ul>
            <h2>The Four Key Principles</h2>
            <ul>
                <li><b>Assessment:</b> Creating rigorous interim assessments that provide meaningful data.</li>
                <li><b>Analysis:</b> Examining the results of assessments to correctly identify the causes of both strengths and shortcomings.</li>
                <li><b>Action:</b> Teaching more effectively what students most need to learn.</li>
                <li><b>Culture:</b> Creating an environment in which data-driven instruction can survive and thrive.</li>
            </ul>
            <Citation
                author="Bambrick-Santoyo, Paul"
                year="2019"
                url="https://www.google.com/books/edition/Driven_by_Data_2_0/cXOSDwAAQBAJ?hl=en&gbpv=0"
                source="Driven By Data 2.0"
            />
        </div>
        <div class="research-item">
            <h2>Assessment Systems</h2>
            <h3>National Curriculum levels</h3>
            <ul>
                <li><b>Example Statement:</b> Pupils show creativity in the way they select specific features or expressions to convey effects and to interest the reader</li>
                <li><b>Possible Levels or Grades:</b> 1-8, and 'exceptional performance'</li>
            </ul>
            <h3>Assessing Pupils' Progress (APP)</h3>
            <ul>
                <li><b>Example Statement:</b> Organize and present whole texts effectively, sequencing and structuring information, ideas and events</li>
                <li><b>Possible Levels or Grades:</b> 1-8, divided into low, secure and high or c / b / a</li>
            </ul>
            <h3>National Curriculum Interim Frameworks for Teacher Assessments</h3>
            <ul>
                <li><b>Example Statement:</b> The pupil can write for a range of purposes and audiences: managing shifts between levels of formality through selecting vocabulary precisely and by manipulating grammatical structures</li>
                <li><b>Possible Levels or Grades:</b> Working towards the expected standard, working at the expected standard, working at greater depth within the expected standard</li>
            </ul>
            <h3>National Association of Head Teachers Key Performance Indicators</h3>
            <ul>
                <li><b>Example Statement:</b> Identifies the audience for, and purpose of, the writing</li>
                <li><b>Possible Levels or Grades:</b>
                    <ol>
                        <li>Working below expectations</li>
                        <li>Working at the expected level</li>
                        <li>Working beyond expectations</li>
                    </ol>
                </li>
            </ul>
            <h3>Herts for Learning</h3>
            <ul>
                <li><b>Example Statement:</b> Evaluates the effectiveness of own and others' writing and suggests improvements</li>
                <li><b>Possible Levels or Grades:</b> Entering, developing, securing, deepening</li>
            </ul>
            <h3>Target Tracker Steps Framework</h3>
            <ul>
                <li><b>Example Statement:</b> Evaluate and edit by assessing the effectiveness of his/her own and others' writing with reasoning</li>
                <li><b>Possible Levels or Grades:</b>
                    <ol>
                        <li>b = beginning</li>
                        <li>b+ = beginning plus</li>
                        <li>w = working within</li>
                        <li>w+ = working within plus</li>
                        <li>s = secure</li>
                        <li>s+ = secure plus</li>
                    </ol>
                </li>
            </ul>
            <Citation
                author="Christodoulou, Daisy"
                year="2017"
                url="https://books.google.com/books/about/Making_Good_Progress.html?id=jM7KDwAAQBAJ&source=kp_book_description"
                source="Making Good Progress?: The future of Assessment for Learning"
            />
        </div>
        <div class="research-item">
            <h2>Other Sources</h2>
            <ul>
                <li><a href="https://nces.ed.gov/nationsreportcard/" target="_blank">National Assessment of Educational Progress (NAEP)</a> (Nation's Report Card)</li>
                <li><a href="https://nces.ed.gov" target="_blank">National Center for Education Statistics (NCES)</a></li>
                <li><a href="https://nces.ed.gov/surveys/pisa/" target="_blank">Program of International Assessment (PISA)</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Citation from "@/components/Research/Citation.vue";
export default {
    name: "ResearchHubMeasuringProgressView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Citation
    }
};
</script>