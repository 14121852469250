<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Who We Are"
        copy="We are a team of believers; passionate, dedicated individuals that care entirely about the well-being of the quality and health of our education system and the families and students that are part of it."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Investing in the Future"
            highlight="Future"
        />
        <p>Together, we are on the journey to securing a better future for our youth and for those following. With dedication to putting the spotlight on education, we are looking forward to what we can make happen.</p>
        <p>It doesn't stop there. From researchers, analysts, educators and individuals passionate about making changes in our education system, we are excited to continue exploring the landscape and we hope you'll join us. <router-link to="/WhatWeDo">Be sure to learn more about what we do</router-link>.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Our Team"
            highlight="Team"
        />
    </div>
    <div id="team">
        <TeamProfile
            class="team-member"
            v-for="(teamMember, t) in Team"
            :key="t"
            :teamMember="teamMember"
        />
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import TeamProfile from "@/components/Team/Profile.vue";
import Team from "@/assets/JS/Team.json";
export default {
    name: "WhoWeAreView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        TeamProfile
    },
    data: () => ({
        bodyIntroductionIcon: {},
        Team
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Who We Are",
            source: "WhoWeAre.png",
            width: 130,
            height: 130
        };
    }
};
</script>