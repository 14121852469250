<template>
    <div class="progress-implementations" :style="`background-color: ${backgroundColor}; border-top: 1px solid ${borderTopColor}`">
        <div v-if="!tracker.none">
            <p v-if="location === 'Top'">
                <CountLabel :tracker="tracker"/>
            </p>
            <ProgressBar
                v-if="!tracker.none"
                :implementing="tracker.implementing"
                :tracking="tracker.tracking"
            />
        </div>
        <div class="reviewing single" v-else>
            <p>Reviewing implementations</p>
        </div>
        <p :class="classes.location" v-if="location === 'Bottom'">
            <CountLabel :tracker="tracker"/>
        </p>
    </div>
</template>

<script>
import ProgressBar from "@/components/Progress/Schools/ProgressBar.vue";
import CountLabel from "@/components/Progress/Schools/Implementations/Tracker/CountLabel.vue";
export default {
    name: "ImplementationTracker",
    props: {
        tracker: Object,
        backgroundColor: String,
        borderTopColor: String,
        location: String
    },
    components: {
        ProgressBar,
        CountLabel
    },
    data: () => ({ classes: { location: "" } }),
    beforeMount() { this.classes.location = this.location === "Bottom" ? "location-left" : ""; }
};
</script>