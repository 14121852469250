<template>
    <div :id="id">
        <div class="breadcrumb flex">
            <div class="flex path-part" v-for="(pathPart, p) in path" :key="p">
                <div class="margin-r" v-if="pathPart.route">
                    <router-link :to="pathPart.route">{{ pathPart.name }}</router-link>
                    {{ Delimiter(p) }}
                </div>
                <div class="margin-r" v-else>
                    <a class="link" @click="$emit('hide-implementation', pathPart.name)">{{ pathPart.name }}</a>
                </div>
                <span v-if="p === path.length - 1"><span>></span> <b>{{ pathPart.current }}</b></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb",
    props: {
        id: String,
        path: Array
    },
    methods: {
        Delimiter: function(pathIndex) { return pathIndex !== this.path.length - 1 ? ">" : ""; }
    },
    emits: ["hide-implementation"]
};
</script>