<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Products &amp; Services"
        copy="The deliverables that compliment our position in education and the results that come from them."
        :padTop="true"
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
export default {
    name: "BodyIntroductionEducation",
    components: { BodyIntroduction },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "ProductsAndServices",
            source: "ProductsAndServices.png",
            width: 130,
            height: 130
        };
    }
};
</script>