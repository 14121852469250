<template>
    <div class="section-divider-dark"></div>
    <div class="align-c">
        <MultiColorHeader
            type="h1"
            heading="Our Focus"
            highlight="Focus"
        />
    </div>
    <div id="focus-categories">
        <Focus
            v-for="(focus, f) in FocusCategories"
            :key="f"
            :focus="focus"
            :index="f"
            :totalCategories="FocusCategories.length"
        />
    </div>
</template>

<script>
import Focus from "@/components/FocusCategories/Focus.vue";
import FocusCategories from "@/assets/JS/FocusCategoryOffers.json";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "FocusCategories",
    components: {
        Focus,
        FocusCategories,
        MultiColorHeader
    },
    data: () => ({ FocusCategories })
};
</script>