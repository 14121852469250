<template>
    <div id="footer" class="narrow-content">
        <div class="flex-x-center">
            <a href="/">
                <img class="logo" src="@/assets/Images/LogoWhite.png" width="190" height="52" :alt="projectName" :title="projectName"/>
            </a>
            <div id="footer-navigation" class="p-desktop">
                <Navigation :navigation="navigation.desktop"/>
            </div>
        </div>
        <div class="p-desktop">
            <p id="tag-line" class="align-c">Learn together, grow together, be together</p>
            <div class="flex-x-center social">
                <SocialIcon
                    type="Facebook"
                    source="IconFacebook.png"
                    width="40"
                    url="https://www.facebook.com/futureforwardeducation"
                />
                <SocialIcon
                    type="Instagram"
                    source="IconInstagram.png"
                    width="40"
                    url="https://www.instagram.com/futureforwardeducation/"
                />
                <SocialIcon
                    type="LinkedIn"
                    source="IconLinkedIn.png"
                    width="40"
                    url="https://www.linkedin.com/company/97404002"
                />
            </div>
        </div>
        <div class="p-mobile">
            <div class="flex-x-center social">
                <SocialIcon
                    type="Facebook"
                    source="IconFacebook.png"
                    width="40"
                    url="https://www.facebook.com/futureforwardeducation"
                />
                <SocialIcon
                    type="Instagram"
                    source="IconInstagram.png"
                    width="40"
                    url="https://www.instagram.com/futureforwardeducation/"
                />
                <SocialIcon
                    type="LinkedIn"
                    source="IconLinkedIn.png"
                    width="40"
                    url="https://www.linkedin.com/company/97404002"
                />
            </div>
            <p id="tag-line" class="align-c">Learn together, grow together, be together</p>
        </div>
    </div>
</template>

<script>
import SocialIcon from "@/components/SocialIcon.vue";
import Navigation from "@/components/Template/Navigation";
export default {
    name: "Footer",
    props: {
        projectName: String,
        navigation: Object
    },
    components: {
        SocialIcon,
        Navigation
    }
};
</script>