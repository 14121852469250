<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="So We Are Aware"
            highlight="Aware"
        />
        <p>Due to that complexity and stigma, for the time being, we are merely positioning ourselves in a way to be a resources for studying and understanding the topics surrounding mental health. Here you will find various resources and information that we've discovered regarding the topic.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Resources</h2>
            <ul>
                <li><a href="https://www.childsavers.org" target="_blank">Child Savers</a> &mdash; Nonprofit</li>
                <li><a href="https://www.mentalhealthfirstaid.org" target="_blank">Mental Health First Aid</a></li>
                <li><a href="https://preventsuicide.lacoe.edu/resources/programs/" target="_blank">Youth Suicide Prevention Project</a></li>
                <li><a href="https://nami.org" target="_blank">National Alliance on Mental Illness (NAMI)</a></li>
                <li><a href="https://www.mhinnovation.net" target="_blank">Mental Health Innovations (MIH)</a></li>
                <li><a href="https://mhanational.org/mentalhealthfacts" target="_blank">Mental Health America (MHA)</a></li>
                <li><a href="https://www.nimh.nih.gov" target="_blank">National Institute for Mental Health (NIMH)</a></li>
                <li><a href="https://www.rethinkstigma.org" target="_blank">Rethink Mental Health Incorporated</a></li>
                <li><a href="https://childmind.org" target="_blank">Child Mind Institute</a></li>
                <li><a href="https://www.facebook.com/AFSPnational" target="_blank">American Foundation for Suicide Prevention</a></li>
                <li><a href="https://strongminds.org" target="_blank">StrongMinds</a></li>
                <li><a href="https://jedfoundation.org" target="_blank">The Jed Foundation (JED)</a></li>
                <li><a href="https://www.rethink.org" target="_blank">Rethink Mental Illness</a></li>
                <li><a href="https://www.sistaafya.com" target="_blank">Sista Afya Community Mental Wellness</a></li>
                <li><a href="https://www.bbrfoundation.org" target="_blank">Brain and Behavior Research Foundation</a></li>
                <li><a href="https://www.activeminds.org" target="_blank">Active Minds</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "ResearchHubStudentMentalHealthView",
    components: {
        BodyIntroduction,
        MultiColorHeader
    }
};
</script>