<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="What We Follow"
            highlight="Follow"
        />
        <p>These are some of the sources of information that we follow and consider to be relevant in context to the nature of work that we do.</p>
    </div>
    <div id="cards-what-we-follow">
        <ReferenceCard
            v-for="(source, s) in WhatWeFollow.Following"
            :key="s"
            :data="source"
            :rootImagePath="WhatWeFollow.RootImagePath"
            :index="s"
        />
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import ReferenceCard from "@/components/Cards/Reference.vue";
import WhatWeFollow from "@/assets/JS/Research/WhatWeFollow.json";
export default {
    name: "WhatWeFollowView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        ReferenceCard
    },
    data: () => ({ WhatWeFollow })
};
</script>