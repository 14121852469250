<template>
    <span :class="['btn tab', classes.state]" @click="$emit('select-tab', { area: tab.area, index })" v-if="tab.active">{{ tab.area }}</span>
</template>

<script>
export default {
    name: "ProgressNavigationTab",
    props: {
        tab: Object,
        index: Number,
        selectedIndex: Number
    },
    data: () => ({ classes: { state: "" } }),
    methods: {
        SetActiveState: function() { this.classes.state = this.selectedIndex === this.index ? "active" : "inactive" }
    },
    mounted() { this.SetActiveState(); },
    updated() { this.SetActiveState(); },
    emits: ["select-tab"]
};
</script>