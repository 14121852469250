<template>
    <div :class="['card m-span', classes.button]" v-if="data.Active">
        <router-link :class="classes.action" :to="action">
            <div class="header" :style="`background-image: url(${require(`@/assets/Images/${data.Watermark}`)}`">
                <img :src="require(`@/assets/Images/${data.IconSource}`)" :alt="`Icon - ${data.Name}`" :title="data.Name" :width="icon.width" :height="icon.height"/>
                <p class="area">{{ data.Name }}</p>
            </div>
            <div class="description">
                <p>{{ data.Description }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        data: Object,
        icon: Object
    },
    data: () => ({
        classes: {
            button: "",
            action: ""
        },
        action: ""
    }),
    mounted() {
        const focusAction = this.data.Action;
        const action = focusAction !== "#";
        this.classes = {
            button: action ? "btn btn-card" : "",
            action: action ? "" : "no-link"
        };
        if (typeof focusAction !== "object") {
            this.action = action ? focusAction : "";
        } else {
            if (action) {
                this.action = {
                    name: focusAction.Name,
                    params: { focus: focusAction.Focus }
                };
            }
        }
    }
};
</script>