<template>
    <h1 class="heading-margin-t-large" v-if="type === 'h1'" v-html="output" :style="`color: ${CONFIG.theme.darkGray}`"></h1>
    <h2 v-if="type === 'h2'" v-html="output" :style="`color: ${CONFIG.theme.darkGray}`"></h2>
</template>

<script>
import { CONFIG } from "@/assets/JS/Config.js"
export default {
    name: "MultiColorHeader",
    props: {
        type: String,
        heading: String,
        highlight: String
    },
    data: () => ({
        CONFIG,
        output: ""
    }),
    mounted() {
        if (this.highlight) {
            this.output = this.heading.replace(this.highlight, `<span style='color: ${CONFIG.theme.blue};'>${this.highlight}</span>`);
        } else {
            this.output = `<span style='color: ${CONFIG.theme.blue};'>${this.heading}</span>`;
        }
    }
};
</script>