<template>
    <BodyIntroduction/>
    <p>Implementation</p>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Progress.vue";
export default {
    name: "SchoolProgressDetailImplementation",
    components: { BodyIntroduction }
};
</script>