<template>
    <ResourceBanner
        source="Cultural &amp; Academic Analysis"
        description="An analysis that highlights demographics details and access to technology in education."
        backgroundColor="backgroundColor"
        height="auto"
        :url="{
            text: 'View Analysis',
            destination: 'https://believeinyou.org/FFE/Documents/Cultural & Academic Analysis.pdf',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
</template>

<script>
import ResourceBanner from "@/components/Banners/SourceReference";
export default {
    name: "CulturalAcademicAnalysisResourceBanner",
    components: { ResourceBanner },
    props: { backgroundColor: String }
};
</script>