<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Believers"
            highlight="Believers"
        />
        <p>We could not do this without the support from our community. We are extremely grateful to any and all support that we receive and that help drive the mission and impact the lives of our youth.</p>
    </div>
    <OfferBanner
        offer="Donors"
        icon="Icons/V4/OurFocus/Partners/Donors.png"
        watermark="Icons/V4/OurFocus/Partners/DonorsWatermark.png"
        :description="['<i>Foundations, groups or individuals that give with their hearts</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>We realize the importance of forming mutual connections and generating purposeful donation plans that compliment the relationship and the sources receiving them. Getting to know their backgrounds and understanding their position in relation to our mission statement is part of that development.</p>
        <p>We did a <a href="https://believeinyou.org/FFE/Documents/Market Analysis - 10-Year Trends.pdf" target="_blank">Market Analysis</a> to determine what the education-focused nonprofit landscape consists of today so we can better understand current donor trends (spanning the past 10 years or so). We were able to scope out the percentages of giving within those trends and recognized the increase/decrease for various age groups. This particular study is referring to individual donors but our findings show that Matures (born before 1945) had the highest percentage of giving (~88%) and that's as of 2017. This was followed by Baby Boomers (1946 - 1964) at ~72% <i><a href="https://institute.blackbaud.com" target="_blank">Blackbaud Institute</a></i>*. These numbers help us to determine our donor audience so we can market ourselves accordingly when looking to build relationships.</p>
        <p>Other findings show that many of these nonprofits are not utilizing tech to its full extent in terms of sustaining communication with their donors. Of the organizations we have been working with, a few of them are sending automated responses via letters to their donors; seemingly unpersonalized and generic. It does not illustrate specific areas of a cause they directly impacted.</p>
    </div>
    <MarketAnalysisSourceReferenceBanner backgroundColor="#fff"/>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Partners.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue"
import MarketAnalysisSourceReferenceBanner from "@/components/Banners/SourceReference/MarketAnalysis.vue";
export default {
    name: "NonprofitsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        MarketAnalysisSourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Research</h2>',
            '<p>Research on donor trends enables us to better determine the correct channels to take when building relationships. <span class="text-focus">This gives us room for conformity to their preferences in methods and approaches to donor-giving</span>, all while showing them the positive results made possible by their generous gift. We will continue to conduct these studies over time as to adjust to evolving trends.</p>',
            '<h2>Building & Sustaining Relationships</h2>',
            '<p>Networking and building sustainabiliity communication plans with potential donors is key. To do this, we want to form a mutual bond so we are familiar with their committment and stance in relation to our mission and <a href="/AboutUs/CoreValues">Core Values</a>. Based on our research in nonprofit trends in regards to donors, we have an idea of the various age groups and giving patterns within them to create marketing materials targeted specifically for those audiences as well as to those we currently have in our network.</p>'
        ];
    }
};
</script>