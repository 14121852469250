<template>
    <SourceReferenceBanner
        source="Market Analysis"
        description="A 10-year review of nonprofit trends including metrics such as <span class='blue-i'>Social</span>, <span class='blue-i'>Economic</span>, <span class='blue-i'>Demographics</span>, <span class='blue-i'>Technology Use</span>, <span class='blue-i'>Donor Patterns</span> and more."
        backgroundColor="#f2f7ff"
        height="auto"
        :url="{
            text: 'View Analysis',
            destination: 'https://believeinyou.org/FFE/Documents/Market Analysis - 10-Year Trends.pdf?v=2',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
</template>

<script>
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "CulturalAcademicAnalysisResourceBanner",
    components: { SourceReferenceBanner }
};
</script>