<template>
    <div class="offer-banner" :style="`height: ${height}px; background-image: url(${require(`@/assets/Images/${watermark}`)}`">
        <div class="icon">
            <img :src="require(`@/assets/Images/${icon}`)" :alt="offer" :title="offer"/>
        </div>
        <h1 class="m-pad">{{ offer }}</h1>
        <div class="description m-pad">
            <p class="single" v-for="(descriptionValue, d) in description" :key="d" v-html="descriptionValue"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "OfferBanner",
    props: {
        offer: String,
        icon: String,
        watermark: String,
        description: Array,
        height: Number
    },
};
</script>