var CONFIG = {
    projectName: "Future Forward Education",
    theme: {
        blue: "#3d6ec4",
        orange: "#f9b438",
        darkGray: "#626464"
    },
    noCarouselRoutes: ["/Progress"],
    showImplementationDetails: false
};

module.exports = { CONFIG };