<template>
    <span class="btn primary" @click="Toggle()">{{ navigationItem.Name }}</span>
    <ul class="sub-navigation-item" v-if="showSubNavigation">
        <li v-for="(subNavigationItem, s) in navigationItem.Children" :key="s">
            <a class="btn" @click="$emit('navigate', subNavigationItem.Action)">{{ subNavigationItem.Name }}</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "NavigationItem",
    props: { navigationItem: Object },
    data: () => ({ showSubNavigation: false }),
    methods: {
        Toggle: function() { this.showSubNavigation = !this.showSubNavigation; }
    },
    emits: ["navigate"]
};
</script>