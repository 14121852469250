<template>
    <div class="contact-card-wrap m-span">
        <div class="contact-card">
            <div class="profile-picture">
                <img :src="require(`@/assets/Images/${profilePicture}`)" :alt="`Icon - ${name}`" :title="name"/>
            </div>
            <div class="information single">
                <div class="details m-align-c">
                    <h3 class="align-c">{{ name }}</h3>

                    <!-- Mobile -->
                    <div class="p-desktop">
                        <ContactDetails :contact="contact"/>
                    </div>
                    <div class="p-mobile">
                        <ContactDetails :contact="contact" :mobile="true"/>
                    </div>

                </div>
                <div v-if="usingSocial">
                    <div class="section-divider"></div>
                    <div class="social">
                        <div class="flex-x-center">
                            <div class="align-c icon-social" v-for="(social, name, s) of contact.Social" :key="s">
                                <a :href="social" target="_blank" v-if="social !== null"><img :src="GetSocialIcon(name)" :alt="`Icon - ${name}`" :title="name"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContactDetails from "@/components/Cards/Contact/Details.vue";
export default {
    name: "ContactCard",
    props: {
        profilePicture: String,
        name: String,
        contact: Object
    },
    components: { ContactDetails },
    data: () => ({ usingSocial: false }),
    methods: {
        GetSocialIcon: function(social) {
            let socialIconSource = "";
            switch(social) {
                case "LinkedIn":
                    socialIconSource = "LinkedIn.png";
                    break;
                case "Facebook":
                    socialIconSource = "Facebook.png";
                    break;
                case "Instagram":
                    socialIconSource = "Instagram.png";
                    break;
                case "Twitter":
                    socialIconSource = "Twitter.png";
                    break;
            }
            return require(`@/assets/Images/Icons/V3/ContactUs/${socialIconSource}`);
        },
        CheckSocial: function() {
            let usingSocial = false;
            for (const social in this.contact.Social) {
                if (this.contact.Social[social] !== null) {
                    usingSocial = true;
                }
            }
            this.usingSocial = usingSocial;
        }
    },
    beforeMount() { this.CheckSocial(); }
};
</script>