<template>
    <Header :projectName="CONFIG.projectName" :navigation="navigation"/>
    <div id="body">
        <router-view/>
    </div>
    <Footer :projectName="CONFIG.projectName" :navigation="navigation"/>
</template>

<script>
import Header from "@/components/Template/Header.vue";
import Footer from "@/components/Template/Footer.vue";
import { CONFIG } from "@/assets/JS/Config.js";
import Navigation from "@/assets/JS/Navigation.js";
export default {
name: "App",
    components: {
        Header,
        Footer
    },
    data: () => ({
        CONFIG,
        navigation: {
            desktop: Navigation.DESKTOP,
            mobile: Navigation.MOBILE
        }
    })
};
</script>