<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="A Growing Crisis"
            highlight="A Growing Crisis"
        />
        <p>We have to be able to understand and become more familiar with the language of youth mental health if we are producing effective, healthier educational experiences. <span class="text-focus">We consider good mental health to be a pre-requisite to performing not only occupationally and functionally but academically as well</span>. As a nation, we are facing a mental health crisis and given the prevalence of mental health disorders in children and adolescents, the odds are that every classroom in America will have at least one student who has a mental health disorder. Approximately one in five children, adolescents and adults are noted to have a mental health disorder, and one in twenty children and adolescents have severe emotional disabilities as also produced in a report by the <i><a href="https://www.cdc.gov/index.htm" target="_blank">Center for Disease Control and Prevention</a></i>*.</p>
    </div>
    <OfferBanner
        offer="Student Mental Health"
        icon="Icons/V4/OurFocus/ProductsAndServices/StudentMentalHealth.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/StudentMentalHealthWatermark.png"
        :description="['<i>Good mental health is a pre-requisite to performing academically and in life</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>More specifically and in our neighborhood, according to the <a href="https://www.lausd.org/domain/4" target="_blank">Los Angeles Unified School District (LAUSD)</a>, twenty-one percent of youth aged 13-18 have a mental illness that causes significant impairment in their daily life. Imagine the number of classrooms with twenty students and 4 to 5 of them having some form of mental health disorder. <span class="text-focus">This is the current reality</span>.</p>
        <p>We want to note that we are not experts on the topic of mental health but do want to highlight these statistics and further research the matter so we can reach a level of cognizance when engaging and working with schools, their students and families. On that, doing further analysis of the role of a school and its position to handling the matter of mental health, we came across a couple alarming discoveries. One being that the <i><a href="https://sites.ed.gov/idea/" target="_blank">Individuals with Disabilities Education Act (IDEA)</a></i>*, a law that makes available a free appropriate public education to eligible children with disabilities throughout the nation and ensures special education and related services to those children, also makes schools be the "payor of last resort" for special education-related services including mental health. Which means, a vast number of schools are reluctant to foot the bill, leaving many students unattended to.</p>
        <p>It is also clear that schools having some form of mental health plan in place is not a required practice and many of them do not <i><a href="https://www.google.com/books/edition/Student_Mental_Health_A_Guide_For_Teache/h4a-DwAAQBAJ?hl=en&gbpv=0" target="_blank">Student Mental Health Guide</a></i>*. This leads to the question of which roles should be responsible for designing such a plan and enforcing it. One major issue of the <span class="text-focus">lack of supports in mental health is the lack of training of our educators to be able to spot symptoms and be the messenger for these types of issues</span>. Compounding this gap is the wide variation in approaches toward student mental health disorders within school districts. Even within a district, there may be major differences in approach from school to school. Schools are the most common settings where youth who have mental health disorders receive any services and they are also a gateway to additional services so therefore, educators play a pivotal role in children's mental health.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue"
export default {
    name: "StudentMentalHealthFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Research & Understanding</h2>',
            '<p>Due to that complexity and stigma, for the time being, we are merely positioning ourselves in a way to be a resources for studying and understanding the topics surrounding mental health. We are not equipped to treat or diagnose these issues but we are equipped to be cognizant of its presence in the academic landscape. We are here to make sure students succeed and understand that good mental health is a pre-requisite to functioning well in the classroom.</p>',
            '<p>We will continue to research the topic and that includes ongoing discussions with schools to determine their role in handling student mental health issues as well as understand what measures they have in place. This will help us refine a dialog around the topic so that we can work on communicating with other expert sources and begin being a liaison or conduit for these types of services.</p>',
            '<p>While visiting schools within <a href="https://www.lausd.org/domain/4" target="_blank">LAUSD</a>, we pulled some data regarding their mental health numbers and this re-validates the <span class="text-focus">roughly quoted 21% of students that are having these struggles</span>. We also noticed a strong desire to seek external sources to assist with their mental health planning. In collaboration with experts, it\'s clear that moving forward, we need to formulate a strategy to determine the number of schools that have developed a mental health plan and those that have not so that we can work on establishing a strategy to indicate what sources, partners and services are being used or are lacking so that we can offer up the concept of creating a plan that meshes across schools. In addition to this, we think another major step would be to assess the type and level of training available for educators (or any school official interfacing with students) so that all of these individuals are more prepared to be the advocates for detecting and responding to the student mental health crisis.</p>'
        ];
    }
};
</script>