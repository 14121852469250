<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="The Method of Teaching"
            highlight="Teaching"
        />
        <p>We study pedagogy to hone in on our abilities to produce solutions for the youth in our communities.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Techniques for Teaching</h2>
            <ul>
                <li><b>Reject Self Report:</b> Replace functionally rhetorical questions with more objective forms of impromptu assessment.</li>
                <li><b>Targeted Questioning:</b> Ask a quick series of carefully chosen, open-ended questions directed at a strategic sample of the class and executed in a short time period.</li>
                <li><b>Standardize the Format:</b> Streamline observations by designing materials and space so that you're looking in the same consistent place every time for the data you need.</li>
                <li><b>Tracking, Not Watching:</b> Be intentional about how you scan your classroom. Decide specifically what you're looking for and remain disciplined about it in the face of distractions.</li>
                <li><b>Show Me:</b> Flip the classroom dynamic in which the teacher gleans data from a passive group of students. Have students actively show evidence of their understanding.</li>
                <li><b>Affirmative Checking:</b> Insert specific points into your lesson when students must get confirmation that their work is correct, productive, or sufficiently rigorous before moving on to the next stage.</li>
                <li><b>Plan for Error:</b> Increase the likelihood that you'll recognize and respond to errors by planning for common mistakes in advance.</li>
                <li><b>Culture of Error:</b> Create an environment where your students feel safe making and discussing mistakes, so you can spend less time hunting for errors and more time fixing them.</li>
                <li><b>Excavate Error:</b> Dig into errors, studying them efficiently and effectively, to better understand where students struggle and how you can best address those points.</li>
                <li><b>Own and Track:</b> Have students correct or revise their own work, fostering an environment of accountability for the correct answer.</li>
                <li><b>No Opt Out:</b> Turn "I don't know" into a success by helping students who won't try or can't succeed practice getting it right (and being accountable for trying).</li>
                <li><b>Right is Right:</b> When you respond to answers in class, hold out for answers that are "all-the-way-right" or all the way to your standards of rigour.</li>
                <li><b>Stretch It:</b> Reward "right" answers with harder questions.</li>
                <li><b>Format Matters:</b> Help students practice responding in a format that communicates the worthiness of their ideas.</li>
                <li><b>Without Apology:</b> Embrace - rather than apologize for - rigorous content, academic challenge, and the hard work necessary to scholarship.</li>
                <li><b>Being With The End:</b> Progress from unit planning to lesson planning. Define the objective, decide how you'll assess it, and then choose appropriate lesson activities.</li>
                <li><b>4 Ms:</b> There are four criteria for an effective lesson plan objective: Manageable, Measureable, Made first, and Most imporant.</li>
                <li><b>Post It:</b> Display your lesson objectives where everyone can see it and identify your purpose.</li>
                <li><b>Double Plan:</b> As you plan a lesson, plan what your students will be doing at each point in class.</li>
                <li><b>Do Now:</b> Use a short warm-up activity that students can complete without instruction or direction from you to start class every day. This lets the learning start even before you begin teaching.</li>
                <li><b>Name the Steps:</b> Break down complex tasks into simple steps that form a path for student mastery.</li>
                <li><b>Board = Paper:</b> Model and shape how students should take notes in order to capture the information you present.</li>
                <li><b>Control the Game:</b> Ask students to read aloud frequently, but manage the process to ensure expressiveness, accountability, and engagement.</li>
                <li><b>Circulate:</b> Move strategically around the room during all parts of the lesson.</li>
                <li><b>At Bats:</b> Because succeeding once or twice at a skill won't bring mastery, give your students lots of practice mastering knowledge and skills.</li>
                <li><b>Exit Ticket:</b> End each class with an explicit assessment of your objective that you can use to evaluate your (and your students') success.</li>
                <li><b>Change the Pace:</b> Establish a productive pace in your classroom. Create 'fast' or 'slow' moments in a lesson by shifting activity types or formats.</li>
                <li><b>Brighten Lines:</b> Ensure that change in activities and other mileposts are perceived clearly by making beginnings and endings of activities visible and crisp.</li>
                <li><b>All Hands:</b> Leverage hand rasiing to positively impact pacing. Manage and vary the ways that students raise their hands, as well as the methods you use to call on them.</li>
                <li><b>Work the Clock:</b> Measure time-your greatest resource as a teacher-intentionally, strategically, and often visibly to shape both your and your students' experience in the classroom.</li>
                <li><b>Every Minute Matters:</b> Respect students' time by spending every minute productively.</li>
                <li><b>Wait Time:</b> Allow students time to think before answering. If they aren't productive with that time, narrate them toward being more productive.</li>
                <li><b>Cold Call:</b> Call on students regardless of whether they've raised their hands.</li>
                <li><b>Call and Response:</b> Ask your class to answer questions in unison from time to time to build energetic, positive engagement.</li>
                <li><b>Break it Down:</b> When a student makes an error, provide just enough help to allow her to 'solve' as much of the original problem as she can.</li>
                <li><b>Pepper:</b> Use Pepper as a fast-paced, vocal review to build energy and actively engage your class.</li>
                <li><b>Everybody Writes:</b> Prepare your students to engage rigorously by giving them the chance to reflect in writing before you ask them to discuss.</li>
                <li><b>The Art of the Sentence:</b> Ask students to synthesize a complex idea in a single, well-crafted sentence. The discipline of having to make one sentence do all the work pushes students to use new syntactical forms.</li>
                <li><b>Show Call:</b> Create a strong incentive to complete writing with quality and thoughtfulness by publicly showcasing and revising student wrting-regardless of who volunteers to share.</li>
                <li><b>Build Stamina:</b> Gradually increase writing time to develop in your students the habit of writing productively, and the ability to do it for sustained periods of time.</li>
                <li><b>Front the Writing:</b> Arrange lessons so that writing comes earlier in the process to ensure that sudents think rigorously in writing.</li>
                <li><b>Habits of Discussion:</b> Make your discussions more productive and enjoyable by normalizing a set of ground rules or 'habits' that allow discussion to be more efficiently cohesive and connected.</li>
                <li><b>Turn and Talk:</b> Encourage students to better formulate their thoughts by including short, contained pair discussion-but make sure to design them for maximum efficiency and accountability.</li>
                <li><b>Batch Process:</b> Give more ownerships and autonomy to students-by allowing for students discussion without teacher mediation, for short periods of time or for longer, more formal sequences.</li>
                <li><b>Threshold:</b> Meet your students at the door, setting expectations before they enter the classroom.</li>
                <li><b>Strong Start:</b> Design and establish an efficient routine for students to enter the classroom and begin class.</li>
                <li><b>STAR/SLANT:</b> Teach students key baseline behaviours for learning, such as sitting up in class and tracking the speaker, by using a memorable acroynm such as STAR or SLANT.</li>
                <li><b>Engineer Efficiency:</b> Teach students the simplest and fastest procedure for executing key classroom tasks, then practice so that executing the procedure becomes a routine.</li>
                <li><b>Strategic Investment - From Procedure to Routine:</b> Turn procedures into routines by rehearsing and reinforcing until excellence becomes habitual. Routinizing a key procedure requires clear expectations, consistency, and most important, patience. Even so, it's almost worth it.</li>
                <li><b>Do It Again:</b> Give students more practice when they're not up to speed-not just doing something again, but doing it better, striving to do their best.</li>
                <li><b>Radar/Be Seen Looking:</b> Prevent non-productive behaviour by developing your ability to see it when it happens and by subtly reminding students that you are looking.</li>
                <li><b>Make Compliance Visible:</b> Ensure that students follow through on a request in an immediate and visible way by setting a standard that's more demanding than marginal compliance. Be judicious in what you ask for, specifically because it will uphold the standard of compliance.</li>
                <li><b>Least Invasive Intervention:</b> Maximize teaching time and minimize 'drama' by using the subtlest and least invasive tactic possible to correct off-task students.</li>
                <li><b>Firm Calm Finesse:</b> Take steps to get compliance without conflict by establishing an environment of purpose and respect and by maintaining your own poise.</li>
                <li><b>Art of the Consequence:</b> Ensure that consequences, when needed, are more effective by making them quick, incremental, consistent and dispersonalized. It also helps to make a bounce-back statement, showing students that they can quickly get back in the game.</li>
                <li><b>Strong Voice:</b> Affirm your authority through intentional verbal and non-verbal habits, especially at moments when you need control.</li>
                <li><b>What to Do:</b> Use specific, concrete, sequential, and observable directions to tell students what to do, as opposed to what not to do.</li>
                <li><b>Positive Framing:</b> Guide students to do better work while motivating and inspiring them by using positive tone to deliver constructive feedback.</li>
                <li><b>Precise Praise:</b> Make your positive reinforcement strategic. Differentiate between acknowledgement and praise.</li>
                <li><b>Warm/Strict:</b> Be both warm and strict at the same time to send a message of high expectations, caring, and respect.</li>
                <li><b>Emotional Constancy:</b> Manage your emotions to consistently promote student learning and achievement.</li>
                <li><b>Joy Factor:</b> Celebrate the work of learning as you go.</li>
            </ul>
            <Citation
                author="Lemov, Doug"
                year="2014"
                url="https://www.google.com/books/edition/Teach_Like_a_Champion_2_0/SKteBAAAQBAJ?hl=en&gbpv=0"
                source="Teach Like a Champion 2.0"
            />
        </div>
        <div class="research-item">
            <h2>The Differentiated Classroom</h2>
            <p>The Teacher modifies Content, Process, and Products Based on Student Readiness, Interest, and Learning Profile</p>
            <ul>
                <li><b>Content:</b> What teachers want students to learn from a particular segment of study, or the materials or mechanisms through which students gain access to that important information</li>
                <li><b>Process:</b> Describes activities designed to ensure that students use key skills to make sense of, apply, and transfer essential knowledge and understandings</li>
                <li><b>Products:</b> Vehicles through which students deomonstrate and extend what they have learned</li>
                <li><b>Readiness:</b> A student's entry point relative to particular knowledge, understanding, or skills</li>
                <li><b>Interest:</b> A Learner's affinitiy, curiousity, or passion for a particular topic or skill</li>
            </ul>
            <Citation
                author="Tomlinson, Carol A."
                year="2014"
                url="https://www.google.com/books/edition/The_Differentiated_Classroom/CLigAwAAQBAJ?hl=en&gbpv=0"
                source="The Differentiated Classroom - Responding to the Needs of All Learner"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Citation from "@/components/Research/Citation.vue";
export default {
    name: "ResearchHubPedagogyView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Citation
    }
};
</script>