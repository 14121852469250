import { createRouter, createWebHistory } from "vue-router";
import Main from "@/views/Main.vue";

// About Us
import AboutUs from "@/views/AboutUs";
import WhoWeAre from "@/views/AboutUs/WhoWeAre.vue";
import WhatWeDo from "@/views/AboutUs/WhatWeDo.vue";
import CoreValues from "@/views/AboutUs/CoreValues.vue";

import Educators from "@/views/Educators.vue";
import OurFocus from "@/views/OurFocus";

// Education
import Education from "@/views/OurFocus/Education";
import EducationGovernance from "@/views/OurFocus/Education/Governance.vue";
import ContentStandards from "@/views/OurFocus/Education/ContentStandards.vue";
import MeasuringProgress from "@/views/OurFocus/Education/MeasuringProgress.vue";
import InadequateSTEMLearning from "@/views/OurFocus/Education/InadequateSTEMLearning.vue";
import LimitedAccess from "@/views/OurFocus/Education/LimitedAccess.vue";
import ClassroomSize from "@/views/OurFocus/Education/ClassroomSize.vue";
import TeacherShortage from "@/views/OurFocus/Education/TeacherShortage.vue";
import StudentMentalHealth from "@/views/OurFocus/Education/StudentMentalHealth.vue";

// Products & Services
import ProductsAndServices from "@/views/OurFocus/ProductsAndServices";
import Content from "@/views/OurFocus/ProductsAndServices/Content.vue";
import TeacherEmpowermentCircle from "@/views/OurFocus/ProductsAndServices/TeacherEmpowermentCircle.vue";
import DigitalLearningBasedApps from "@/views/OurFocus/ProductsAndServices/DigitalLearningBasedApps.vue";
import TeacherWishList from "@/views/OurFocus/ProductsAndServices/TeacherWishList.vue";
import SchoolResourceInsightsSurvey from "@/views/OurFocus/ProductsAndServices/SchoolResourceInsightsSurvey.vue";
import OtherDonations from "@/views/OurFocus/ProductsAndServices/OtherDonations";
import ServiceStudentMentalHealth from "@/views/OurFocus/ProductsAndServices/StudentMentalHealth.vue";

// Partners
import Partners from "@/views/OurFocus/Partners";
import Nonprofits from "@/views/OurFocus/Partners/Nonprofits.vue";
import EducationalEstablishments from "@/views/OurFocus/Partners/EducationalEstablishments.vue";
import Donors from "@/views/OurFocus/Partners/Donors.vue";

// Research
import Research from "@/views/Research";
import WhatWeFollow from "@/views/Research/WhatWeFollow.vue";
import ResearchHub from "@/views/Research/Hub/";

import HubPedagogy from "@/views/Research/Hub/Pedagogy.vue";
import HubMeasuringProgress from "@/views/Research/Hub/MeasuringProgress.vue";
import HubData from "@/views/Research/Hub/Data.vue";
import HubSchools from "@/views/Research/Hub/Schools.vue";
import HubMarketAnalysis from "@/views/Research/Hub/MarketAnalysis.vue";
import HubStudentMentalHealth from "@/views/Research/Hub/StudentMentalHealth.vue";

import ContactUs from "@/views/ContactUs.vue";
import Donate from "@/views/Donate.vue";
import Progress from "@/views/Progress";
import SchoolProgressDetail from "@/views/Progress/School/";

const routes = [
    {
        path: "/",
        name: "Main",
        component: Main
    },
    {
        path: "/AboutUs",
        name: "AboutUs",
        component: AboutUs
    },
    {
        path: "/AboutUs/WhoWeAre",
        name: "WhoWeAre",
        component: WhoWeAre
    },
    {
        path: "/AboutUs/WhatWeDo",
        name: "WhatWeDo",
        component: WhatWeDo
    },
    {
        path: "/AboutUs/CoreValues",
        name: "CoreValues",
        component: CoreValues
    },
    {
        path: "/Educators",
        name: "Educators",
        component: Educators
    },
    {
        path: "/OurFocus",
        name: "OurFocus",
        component: OurFocus
    },
    {
        path: "/OurFocus/Education",
        name: "Education",
        component: Education
    },
    {
        path: "/OurFocus/Education/Governance",
        name: "EducationGovernance",
        component: EducationGovernance
    },
    {
        path: "/OurFocus/Education/ContentStandards",
        name: "ContentStandards",
        component: ContentStandards
    },
    {
        path: "/OurFocus/Education/MeasuringProgress",
        name: "MeasuringProgress",
        component: MeasuringProgress
    },
    {
        path: "/OurFocus/Education/InadequateSTEMLearning",
        name: "InadequateSTEMLearning",
        component: InadequateSTEMLearning
    },
    {
        path: "/OurFocus/Education/LimitedAccess",
        name: "LimitedAccess",
        component: LimitedAccess
    },
    {
        path: "/OurFocus/Education/ClassroomSize",
        name: "ClassroomSize",
        component: ClassroomSize
    },
    {
        path: "/OurFocus/Education/TeacherShortage",
        name: "TeacherShortage",
        component: TeacherShortage
    },
    {
        path: "/OurFocus/Education/StudentMentalHealth",
        name: "StudentMentalHealth",
        component: StudentMentalHealth
    },
    {
        path: "/OurFocus/ProductsAndServices",
        name: "ProductsAndServices",
        component: ProductsAndServices
    },
    {
        path: "/OurFocus/ProductsAndServices/Content",
        name: "Content",
        component: Content
    },
    {
        path: "/OurFocus/ProductsAndServices/TeacherEmpowermentCircle",
        name: "TeacherEmpowermentCircle",
        component: TeacherEmpowermentCircle
    },
    {
        path: "/OurFocus/ProductsAndServices/DigitalLearningBasedApps",
        name: "DigitalLearningBasedApps",
        component: DigitalLearningBasedApps
    },
    {
        path: "/OurFocus/ProductsAndServices/TeacherWishList",
        name: "TeacherWishList",
        component: TeacherWishList
    },
    {
        path: "/OurFocus/ProductsAndServices/SchoolResourceInsightsSurvey",
        name: "SchoolResourceInsightsSurvey",
        component: SchoolResourceInsightsSurvey
    },
    {
        path: "/OurFocus/ProductsAndServices/OtherDonations",
        name: "OtherDonations",
        component: OtherDonations
    },
    {
        path: "/OurFocus/ProductsAndServices/StudentMentalHealth",
        name: "ServiceStudentMentalHealth",
        component: ServiceStudentMentalHealth
    },
    {
        path: "/OurFocus/Partners",
        name: "Partners",
        component: Partners
    },
    {
        path: "/OurFocus/Partners/Nonprofits",
        name: "Nonprofits",
        component: Nonprofits
    },
    {
        path: "/OurFocus/Partners/EducationalEstablishments",
        name: "EducationalEstablishments",
        component: EducationalEstablishments
    },
    {
        path: "/OurFocus/Partners/Donors",
        name: "Donors",
        component: Donors
    },
    {
        path: "/Research",
        name: "Research",
        component: Research
    },
    {
        path: "/Research/WhatWeFollow",
        name: "WhatWeFollow",
        component: WhatWeFollow
    },
    {
        path: "/Research/Hub",
        name: "ResearchHub",
        component: ResearchHub
    },
    {
        path: "/Research/Hub/Pedagogy",
        name: "HubPedagogy",
        component: HubPedagogy
    },
    {
        path: "/Research/Hub/MeasuringProgress",
        name: "HubMeasuringProgress",
        component: HubMeasuringProgress
    },
    {
        path: "/Research/Hub/Data",
        name: "HubData",
        component: HubData
    },
    {
        path: "/Research/Hub/Schools",
        name: "HubSchools",
        component: HubSchools
    },
    {
        path: "/Research/Hub/MarketAnalysis",
        name: "HubMarketAnalysis",
        component: HubMarketAnalysis
    },
    {
        path: "/Research/Hub/StudentMentalHealth",
        name: "HubStudentMentalHealth",
        component: HubStudentMentalHealth
    },
    {
        path: "/ContactUs",
        name: "ContactUs",
        component: ContactUs
    },
    {
        path: "/Donate",
        name: "Donate",
        component: Donate
    },
    {
        path: "/Progress",
        name: "Progress",
        component: Progress
    },
    {
        path: "/Progress/School/:name",
        name: "SchoolProgressDetail",
        component: SchoolProgressDetail
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;