<template>
    <div class="section-divider"></div>
    <p class="source"><span class="author">{{ author }}</span>, {{ year }} &mdash; <a :href="url" target="_blank">{{ source }}</a></p>
</template>

<script>
export default {
    name: "Citation",
    props: {
        author: String,
        year: String,
        url: String,
        source: String
    }
};
</script>