<template>
    <BodyIntroduction/>

    <!-- Progress navigation -->
    <div class="tab-navigation">
        <div class="wide-content">
            <TabNavigation
                :tabs="AREAS"
                :selectedTab="selectedTab"
                @select-tab="SelectTab($event)"
            />
            <div class="right">
                <img class="btn btn-information" src="@/assets/Images/Icons/Information.png" width="50" alt="Icon - Information" title="Information" @click="OpenPopup('A Look Forward', 'ProgressIntroduction')"/>
            </div>
        </div>
    </div>

    <div class="section-divider"></div>
    <div class="wide-content">

        <!-- Progress Areas: Gets toggled by the navigation tab selections -->
        <Areas :area="selectedTab.area"/>

    </div>
    <Popup :popup="popup" @close="ClosePopup()"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Progress.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Areas from "@/components/Progress/Schools/Areas/";
import TabNavigation from "@/components/Navigations/Tabs/";
import Popup from "@/components/Popups/"
import {
    AREAS,
    PROGRESS
} from "@/assets/JS/Progress/Progress.js";
export default {
    name: "ProgressView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Areas,
        TabNavigation,
        Popup
    },
    data: () => ({
        bodyIntroductionIcon: {},
        PROGRESS,
        popup: { title: "" },
        AREAS,
        selectedTab: {}
    }),
    methods: {
        OpenPopup: function(title, content) {
            this.popup = {
                show: true,
                title,
                content
            };
        },
        ClosePopup: function() { this.popup = {}; },
        SelectTab: function(tab) {
            this.selectedTab = {
                area: tab.area,
                index: tab.index
            };
        }
    },
    beforeMount() {
        PROGRESS.SetAreaIDs();
        this.selectedTab = {
            area: "Products & Services",
            index: 0
        };
    }
};
</script>