<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Reviewing the System"
            highlight="System"
        />
        <p>Evidence suggests that the system of education today is in a state of decline. From student performance to issues of inequity and lack of access to much-needed resources, which are alarming problems for schools, educators, students and their families. Some of these problems span across years, even decades, including student progress. This has been on the decline as made evident in the <a href="https://nces.ed.gov/nationsreportcard/" target="_blank">National Assessment of Educational Progress (NAEP)</a>, also known as the Nation's Report Card, especially in areas of Math and English Language Arts (ELA). Here, we will look into what some of these issues are and look at possible solutions that may be necessary to climb out of a failing, stagnant education system.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h2"
            heading="What We Are Observing"
            highlight="Observing"
        />
        <div class="cards flex flex-wrap">
            <Card
                v-for="(focus, f) in Education"
                :key="f"
                :data="focus"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import Education from "@/assets/JS/Cards/OurFocus/Education.json";
export default {
    name: "EducationView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({
        bodyIntroductionIcon: {},
        Education
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Education",
            source: "Education.png",
            width: 130,
            height: 130
        };
    }
};
</script>