<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Data-Driven Solutions"
            highlight="Solutions"
        />
        <p>Data is in everything we do. Data allows us to find issues and enables us to develop alternative measures for creating change.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Global Education Data</h2>
            <ul>
                <li><a href="https://www.unesco.org" target="_blank">UNESCO</a> &mdash; Global Education Monitoring Report</li>
                <li><a href="https://www.worldbank.org" target="_blank">World Bank</a></li>
            </ul>
        </div>
        <div class="research-item">
            <h2>California Department of Education</h2>
            <ul>
                <li><a href="https://www.cde.ca.gov/ds/ad/dataquest.asp" target="_blank">Data Quest</a> &mdash; Teaching Assignment Monitoring Outcomes</li>
                <li><a href="https://www.cde.ca.gov/ta/ac/sa/" target="_blank">School Accountability Report Card</a></li>
            </ul>
        </div>
        <div class="research-item">
            <h2>For Schools</h2>
            <ul>
                <li><a href="https://www.caschooldashboard.org" target="_blank">California School Dashboard</a></li>
                <li><a href="https://www.webudgetla.org" target="_blank">WeBudget</a></li>
                <li><a href="https://nces.ed.gov/nationsreportcard/" target="_blank">National Assessment of Educational Progress (NAEP)</a> (Nation's Report Card)</li>
                <li><a href="https://www.cde.ca.gov/ta/ac/sa/" target="_blank">School Accountability Report Card (SARC)</a></li>
            </ul>
        </div>
        <div class="research-item">
            <h2>U.S. Department of Education</h2>
            <ul>
                <li><a href="https://www.ed.gov/equity" target="_blank">Federal Teacher Equity Plan</a></li>
            </ul>
        </div>
        <div class="research-item">
            <h2>Other Sources</h2>
            <ul>
                <li><a href="https://nces.ed.gov" target="_blank">National Center for Education Statistics (NCES)</a></li>
                <li><a href="https://nces.ed.gov/nationsreportcard/" target="_blank">National Assessment of Educational Progress (NAEP)</a> (Nation's Report Card)</li>
                <li><a href="https://nces.ed.gov/surveys/pisa/" target="_blank">Program of International Assessment (PISA)</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "ResearchHubDataView",
    components: {
        BodyIntroduction,
        MultiColorHeader
    }
};
</script>