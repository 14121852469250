<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="A Market Review"
            highlight="A Market Review"
        />
        <p>Any research pertaining to the industry as it relates to our efforts in educational development. This type of analysis also includes cross-reference and comparisons to other educational establishments, nonprofits, industry-relevant metrics that impact the work we do and more.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Market Overview</h2>
            <ul>
                <li><a href="https://believeinyou.org/FFE/Documents/Market Analysis - 10-Year Trends.pdf?v=2" target="_blank">10-Year Trends for Education-focused Nonprofits</a></li>
                <li><a href="https://believeinyou.org/FFE/Documents/Cultural & Academic Analysis.pdf" target="_blank">Cultural & Academic Analysis</a></li>
            </ul>
        </div>
        <div class="research-item">
            <h2>Community Demographics</h2>
            <ul>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Computer & Internet Use, Ethnicity.xls">Community Segmentation - Computer &amp; Internet Use, Ethnicity</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Computer & Internet Use.xls">Community Segmentation - Computer &amp; Internet Use</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Education, Ethnicity.xls">Community Segmentation - Education, Ethnicity</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Education.xls">Community Segmentation - Education</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Ethnicity.xls">Community Segmentation - Ethnicity</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Income & Poverty, Ethnicity.xls">Community Segmentation - Income &amp; Poverty, Ethnicity</a> (Excel Spreadsheet)</li>
                <li><a href="https://believeinyou.org/FFE/Data/Community Segmentation - Income & Poverty.xls">Community Segmentation - Income &amp; Poverty</a> (Excel Spreadsheet)</li>
            </ul>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "ResearchHubMarketAnalysisView",
    components: {
        BodyIntroduction,
        MultiColorHeader
    }
};
</script>