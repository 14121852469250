<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Contact Us"
        copy="Let's stay in touch. We are excited to make new friends, meet industry experts, expand our network and continue this journey with all of you."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Let's Connect"
            highlight="Connect"
        />
        <p>Please feel free to reach out through any of our points of contact. <span class="text-focus">We are excited to hear from you</span>.</p>
    </div>
    <div class="wide-content">
        <div class="cards flex-x-center flex-wrap icon-cards m-block">
            <IconCard
                icon="Icons/V3/ContactUs/Email.png"
                type="Email"
                url="mailto:mikeross@futureforwardeducation.org"
            />
            <IconCard
                icon="Icons/V3/ContactUs/Facebook.png"
                type="Facebook"
                url="https://www.facebook.com/futureforwardeducation"
            />
            <IconCard
                icon="Icons/V3/ContactUs/Instagram.png"
                type="Instagram"
                url="https://www.instagram.com/futureforwardeducation/"
            />
        </div>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <div class="align-c">
            <MultiColorHeader
                type="h2"
                heading="Let's Talk Education"
                highlight="Education"
            />
        </div>
        <div class="cards contact-cards flex-x-center flex-wrap m-block">
            <ContactCard
                profilePicture="Team/BrandynTorres.png"
                name="Brandyn Torres"
                :contact="GetTeamMemberContact('Brandyn Torres')"
            />
            <ContactCard
                profilePicture="Team/EricaSklar.png"
                name="Erica Sklar"
                :contact="GetTeamMemberContact('Erica Sklar')"
            />
            <ContactCard
                profilePicture="Team/MikeRoss.png"
                name="Mike Ross"
                :contact="GetTeamMemberContact('Mike Ross')"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import IconCard from "@/components/Cards/Icon.vue";
import ContactCard from "@/components/Cards/Contact";
import Team from "@/assets/JS/Team.json";
export default {
    name: "ContactUsView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        IconCard,
        ContactCard
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    methods: {
        GetTeamMemberContact: function(teamMember) {
            const teamMemberIndex = Team.map(function(e) { return e.Name; }).indexOf(teamMember);
            return Team[teamMemberIndex].Contact;
        }
    },
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Contact Us",
            source: "ContactUs.png",
            width: 130,
            height: 130
        };
    }
};
</script>