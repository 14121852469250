<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Limiting the Ability to Learn"
            highlight="Limiting the Ability to Learn"
        />
        <p>In Southern California and more specifically in the Los Angeles area, there are communities that fall under the category of being impoverished or are considered socioeconomically disadvantaged where access to quality education might be limited or inaccessible. We feel that all growing minds are deserving more access to proper, quality learning experiences. Youth growing up without these things are more at risk of having a reduced quality of life. Access to proper tools and resources improves the likeliness that they can position themselves for greater success for the road ahead.</p>
    </div>
    <OfferBanner
        offer="Limited or Denied Access to Resources"
        icon="Icons/V4/OurFocus/Education/LimitedAccess.png"
        watermark="Icons/V4/OurFocus/Education/LimitedAccessWatermark.png"
        :description="['<i>A scarcity we can\'t afford to keep experiencing</i>']"
        :height="380"
    />
    <div class="wide-content">
        <h2>In Our Neighborhood</h2>
        <p>Furthermore, research conducted through the <i><a href="https://www.universityofcalifornia.edu" target="_blank">University of California</a> and the <a href="https://www.cetfund.org" target="_blank">California Emerging Technology Fund</a></i>* indicates that progress has been made in regards to the percentage of Californians that have access to the internet. However, it remains true that low-income households are at a clear disadvantage when it comes to connectivity, as income is directly related to broadband access. More specifically, students at the second largest school district in the nation, the <a href="https://www.lausd.org/domain/4" target="_blank">Los Angeles Unified School District (LAUSD)</a>, <span class="text-focus">are particularly at the mercy of education inequality as a result of the digital divide</span>.</p>
        <p>While many students in LAUSD schools without access to the internet were provided with hotspots and laptop devices, there has not been enough broadband infrastructure to support the use of this technology. Research conducted by the <i><a href="https://www.solaican.org" target="_blank">SURGE project and SoLa I CAN Foundation</a></i>* found that <span class="text-focus">87% of students in the 13 largest LAUSD high schools lack access to tech-related activities</span>.</p>
        <p>Additional sources of data among LAUSD students when referring to their <a href="https://www.cde.ca.gov/ta/tg/sa/sbacinterimassess.asp" target="_blank">Smarter Balanced Assessment Criteria (SBAC)</a> scores and school climate survey feedback will also show areas in which gaps in both instructional materials and resources are present. These types of surveys also help to highlight a school body as a type of resource and the evidence of staff-to-student ratio imabalances.</p>
    </div>
    <CulturalAcademicAnalysisSource/>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import CulturalAcademicAnalysisSource from "@/components/Banners/SourceReference/CulturalAcademicAnalysis.vue";
export default {
    name: "LimitedAccessFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        CulturalAcademicAnalysisSource
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Research & Understanding</h2>',
            '<p>In reference to the term Resources, these can be considered as many things; items such as backpacks and uniforms, or learning materials and programming. Much of this comes down to quite a few variables such as demographics, the digital divide, socioeconomically disadvantaged communities among others. We want to remain cognizant of these communities and incorporate it in any analysis reporting we do when defining solutions. That includes understanding the limitations to certain technologies.</p>',
            '<p>We would like to point out that in our efforts to familiarize ourselves with the limitations to resources for our youth in the hopes to create more equity, we do want to iterate that <span class="text-focus">equity is defined by the student</span> and that going forward with our implementations is heavily dependent on our ongoing studies and relying more on the voice of the student.</p>'
        ];
    }
};
</script>