<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Partners"
        copy="Continued support by individuals, groups and other stakeholders sharing a passion for educational change."
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
export default {
    name: "BodyIntroductionEducation",
    components: { BodyIntroduction },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Partners",
            source: "Partners.png",
            width: 130,
            height: 130
        };
    }
};
</script>