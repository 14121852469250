<template>
    <BodyIntroduction/>
    <Breadcrumb
        id="progress-detail-breadcrumb"
        :path="breadcrumbPath"
        @hide-implementation="HideImplementation($event)"
    />
    <div id="school-progress" :style="`border-top: 15px solid ${school.Colors.Primary};`">
        <div id="top" class="flex">
            <div class="flex">
                <img class="logo m-w-10" :src="require(`@/${school.Images.Logo}`)" :alt="`Icon - ${fullName}`" :title="fullName" :style="`border: 2px solid ${school.Colors.Primary}; background-color: ${school.Colors.LogoBackdrop};`"/>
            </div>
            <div id="details" class="row">
                <div id="heading">
                    <h1>{{ school.Name.Primary }}</h1>
                    <h2>{{ school.Grades.Type }} School</h2>
                </div>
                <ImplementationTracker
                    :tracker="implementations.tracker"
                    backgroundColor="transparent"
                    borderTopColor="none"
                    location="Bottom"
                />
            </div>
        </div>
        <div id="top-underlay"></div>
        <div id="card-implementations" class="cards flex-center flex-wrap" v-if="!ImplementationSelected()">
            <Card
                v-for="(trackedImplementation, i) in implementations.tracked"
                :key="i"
                :data="trackedImplementation"
                :school="fullName"
                :icon="{ width: 200, height: 200 }"
                @show-implementation="ShowImplementation($event)"
            />
        </div>
        <div v-else>
            <Card
                :key="i"
                :data="implementations.selected"
                :school="fullName"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import ImplementationTracker from "@/components/Progress/Schools/Implementations/Tracker/";
import Card from "@/components/Cards/Progress/Implementation.vue";
import BodyIntroduction from "@/components/BodyIntroduction/Progress.vue";
import Breadcrumb from "@/components/Navigations/Breadcrumb.vue";
import { PROGRESS } from "@/assets/JS/Progress/Progress.js";
import { CONFIG } from "@/assets/JS/Config.js";
export default {
    name: "SchoolProgressView",
    components: {
        ImplementationTracker,
        Card,
        BodyIntroduction,
        Breadcrumb
    },
    data: () => ({
        school: {},
        fullName: "",
        implementations: {
            area: "",
            tracker: {
                implementing: 0,
                tracking: 0,
                none: false
            },
            implemented: {},
            tracked: {},
            selected: {}
        },
        breadcrumbPath: {},
        implementationRoute: {}
    }),
    methods: {
        ShowImplementation: function(implementation) {
            if (CONFIG.showImplementationDetails) {
                this.implementations.selected = implementation;
                this.breadcrumbPath.push({
                    name: this.implementations.area,
                    current: implementation.Name
                });
            }
        },
        HideImplementation: function(implementation) {
            this.implementations.selected = {};
            this.breadcrumbPath = this.breadcrumbPath.filter(b => b.name !== implementation);
        },
        ImplementationSelected: function() {
            return Object.keys(this.implementations.selected).length > 0;
        }
    },
    beforeMount() {
        this.school = PROGRESS.Sources.Schools.find(s => (s.Name.Full !== undefined ? s.Name.Full.replace(/ /gi, "") : s.Name.Primary.replace(/ /gi, "")) === this.$route.params.name);
        const schoolNameSecondary = this.school.Name.Secondary !== null ? this.school.Name.Secondary : "";
        this.fullName = `${this.school.Name.Primary} ${schoolNameSecondary}`;

        // Get progress area implementations
        PROGRESS.GetProgressAreaImplementations(this, this.school.Progress, "Products & Services");

        this.breadcrumbPath = [
            {
                name: "Progress",
                route: { name: "Progress" },
                current: this.implementations.area
            }
        ];
    }
};
</script>