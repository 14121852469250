<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="The Academic Landscape"
            highlight="The Academic Landscape"
        />
        <p>Schools are a primary hub of information for us and is where much of our work is done. Every school has its nuance and can be seen as a blank canvas at times from the rest but as we continue to dig into this landscape, we are excited to become more familiar with the individual functions of our schools as well learning more about the consistencies and conventions that make them what they are.</p>
    </div>
    <div class="section-divider"></div>
    <div id="research-list" class="wide-content">
        <div class="research-item">
            <h2>Funding</h2>
            <ul>
                <li><a href="https://edbuild.org" target="_blank">EdBuild</a> &mdash; Nonprofit that tracks school funding</li>
                <li><a href="https://www.ppic.org/publication/financing-californias-public-schools/" target="_blank">Financing Public Schools in CA</a></li>
                <li><a href="https://www.cde.ca.gov/fg/aa/lc/" target="_blank">Local Control Funding Formula (LCFF)</a></li>
                <li>State Funds</li>
                <li><a href="https://www.cde.ca.gov/re/lc/" target="_blank">Local Control and Accountability Plan (LCAP)</a> &mdash; School districts work with parents, educators, students and community to develop this</li>
                <li><a href="https://www.cde.ca.gov/fg/cr/esser.asp" target="_blank">Elementary and Secondary Schools Emergency Relief (ESSER)</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "ResearchHubSchoolsView",
    components: {
        BodyIntroduction,
        MultiColorHeader
    }
};
</script>