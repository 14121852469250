<template>
    <div class="icon-card-wrap m-span">
        <div class="icon-card" :style="styles.borderTop">
            <a class="btn-card" :href="url" target="_blank">
                <div class="icon">
                    <img class="m-w-10" :src="require(`@/assets/Images/${icon}`)" :alt="`Icon - ${type}`" :title="type"/>
                </div>
                <p class="align-c type">{{ type }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconCard",
    props: {
        icon: String,
        type: String,
        url: String,
        showTopBorder: Boolean
    },
    data: () => ({ styles: { borderTop: "" } }),
    beforeMount() {
        if (this.showTopBorder) {
            this.styles.borderTop = "border-top: 10px solid #f9b438";
        }
    }
};
</script>