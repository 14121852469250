<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="An Important Relationship"
            highlight="Important Relationship"
        />
        <p>We know where are key audiences spend much of their time. It's essential that we form relationships with schools so that we can work as closely as possible with our customers.</p>
    </div>
    <OfferBanner
        offer="Educational Establishments"
        icon="Icons/V4/OurFocus/Partners/EducationalEstablishments.png"
        watermark="Icons/V4/OurFocus/Partners/EducationalEstablishmentsWatermark.png"
        :description="['<i>A school, institute or any body of educators practicing their craft for our youth</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>An educational establishment is defined as a school, institute or any body of educators that are passionate and motivated to offer their knowledge and resources to teach others within a learning environment. Our mission is not only best supported through nonprofit and donor collaborations but through educational establishments as well. As noted a few times, <span class="text-focus">we feel it's essential that we are present in the system and is crucial to accomplishing our mission objectives</span>. It is in this environment that we can see first-hand what the academic landscape looks like and that of which enables us to work more closely with our customers.</p>
        <p>Partnering with schools, where the student body falls under our demographic audience, will help us to expand our services and allow us to conduct more analysis, integrate our findings and design content into an already-established establishment. We can also cater to a wider range of ages and subjects due to having more access to classroom environments, operations and educators.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
    <SourceReferenceBanner
        source="School Connectivity Plan"
        description="An overview of the various points of contact we make with educational establishments, increasing connectivity and support."
        backgroundColor="#f2f7ff"
        height="auto"
        :url="{
            text: 'View Plan',
            destination: 'https://believeinyou.org/FFE/Documents/School Connectivity Plan.pdf?v=2',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Partners.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "NonprofitsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        SourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Sustaining Relationships</h2>',
            '<p>Currently, we are engaging with multiple local middle schools within the <a href="https://www.lausd.org/domain/4" target="_blank">Los Angeles Unified School District (LAUSD)</a>. We have been in discussion regarding conducting more research and program implementation. We want to utilize more schools to continue forming a relationship and gaining access to students. Part of the program implementation includes our services and program creation through various deliverables.</p>',
            '<p>Through the various contacts we have from our educators, we would like to get in touch with other local schools to continue our efforts as well as the possibility of influencing program generation. Keeping in touch with and building new relationships of schools will help us amass a larger network of dedicated individuals to help us support our cause.</p>',
            '<h2>Integration</h2>',
            '<p>During our visits with schools, we have already discovered multiple avenues in which we can offer up more diverse products and services and to a wide collection of the school body such as administrators, teachers, students and their families. Some of our current implementations include the items discussed from our <a href="/OurFocus/ProductsAndServices">Products & Services</a>; <a href="/OurFocus/ProductsAndServices/Content">Content</a>, such as curriculum and instructional materials, the <a href="/OurFocus/ProductsAndServices/TeacherEmpowermentCircle">Teacher Empowerment Circle (TEC)</a> initiative, the <a href="/OurFocus/ProductsAndServices/TeacherWishList">Teacher Wish List</a>, the <a href="/OurFocus/ProductsAndServices/SchoolResourceInsightsSurvey">School Resources Insights Survey</a> and <a href="/OurFocus/ProductsAndServices/OtherDonations">donations</a> through their Parent Centers.</p>',
            '<h2>Presence</h2>',
            '<p>We want to increase the amount of time spent within the system and gain better understandings of how it functions. By doing so, we can be more direct with the products we deliver, the services we offer and engagement with the school body, <span class="text-focus">getting us closer to creating a much-needed change for education</span>.</p>'
        ];
    }
};
</script>