<template>
    <div class="flex m-block">
        <div class="align-c profile m-span">
            <img :src="require(`@/assets/Images/${teamMember.ProfileImage}`)" :alt="`Profile - ${teamMember.Name}`" :title="teamMember.Name" width="200" height="200"/>
            <h4>{{ teamMember.Name }}</h4>
            <h3>{{ teamMember.Category }}</h3>
            <div class="divider"></div>
            <p class="single title">{{ teamMember.Title }}</p>
        </div>
        <div class="description m-span">
            <p v-for="(description, d) in teamMember.Description" :key="d">{{ description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Profile",
    props: { teamMember: Object }
};
</script>