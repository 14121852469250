<template>
    <h3 class="btn btn-a" :style="`color: ${headingColor}`">Focus &amp; Strategies</h3>
    <img class="toggle-arrow" :src="indicatorSource" :alt="`Arrow - ${direction}`" :title="`Arrow - ${direction}`" width="20" height="20"/>
</template>

<script>
export default {
    name: "AreaToggle",
    props: {
        headingColor: String,
        expanded: Boolean,
        arrowColor: String
    },
    data: () => ({
        indicatorSource: "",
        direction: ""
    }),
    beforeMount() {
        const source = this.arrowColor === "White" ? "ArrowRightWhite.png" : "ArrowRightBlue.png"
        this.indicatorSource = require(`@/assets/Images/${source}`);
        this.direction = "Right";
    },
    updated() {
        const source = this.arrowColor === "White"
            ? ["ArrowDownWhite.png", "ArrowRightWhite.png"]
            : ["ArrowDownBlue.png", "ArrowRightBlue.png"];
        this.indicatorSource = this.expanded
            ? require(`@/assets/Images/${source[0]}`)
            : require(`@/assets/Images/${source[1]}`);
        this.direction = !this.expanded ? "Right" : "Down";
    }
};
</script>