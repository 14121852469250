<template>
    <div :class="['button', classes.state]" :style="`background-color: ${styles.backgroundColor}`" @click="$emit('navigate', { index, previous: state.selected })"></div>
</template>

<script>
export default {
    name: "CarouselNavigation",
    props: {
        index: Number,
        slide: Object,
        state: Object
    },
    data: () => ({
        classes: { state: "" },
        styles: { backgroundColor: "" }
    }),
    beforeMount() {
        this.classes.state = this.index === 0 ? "active" : "inactive";
        this.styles.backgroundColor = this.classes.state === "active"
            ? this.slide.NavigationColors.Active
            : this.slide.NavigationColors.Inactive
    },
    updated() {
        this.classes.state = this.state.selected === this.index ? "active" : "inactive";
        this.styles.backgroundColor = this.classes.state === "active"
            ? this.slide.NavigationColors.Active
            : this.slide.NavigationColors.Inactive;
    },
    emits: ["navigate"]
};
</script>