<template>
    <div :class="['focus', classes.even]">

        <!-- Mobile -->
        <div class="p-desktop">
            <p class="align-c introduction m-pad single" v-for="(introductionItem, i) in focus.Introduction" :key="i" v-html="introductionItem"></p>
        </div>
        <div class="align-c m-pad p-mobile">
            <span class="introduction" v-for="(introductionItem, i) in focus.Introduction" :key="i" v-html="` ${introductionItem}`"></span>
        </div>

        <div class="content offer-banner-main" :style="`background-color: ${styles.backgroundColor}; background-image: url(${require(`@/assets/Images/${focus.Watermark}`)}`">
            <div class="icon">
                <img :src="require(`@/assets/Images/${focus.Icon.Source}`)" :alt="focus.Category" :title="focus.Category"/>
            </div>
            <div class="flex-x-center m-pad narrow-content">
                <div class="copy">
                    <h1 class="align-c m-pad" :style="`color: ${styles.headingColor}`">{{ focus.Category }}</h1>
                    <p class="m-pad" :style="`color: ${styles.copyColor}`">{{ focus.Summary }}</p>
                    <div class="focus-areas" :style="`color: ${styles.copyColor}`" @click="Toggle(expandAreas)">
                        <div class="flex m-pad">
                            <AreaToggle
                                :headingColor="styles.headingColor"
                                :expanded="expandAreas"
                                :arrowColor="styles.headingColor === '#fff' ? 'White' : 'Blue'"
                            />
                        </div>

                        <!-- Mobile -->
                        <div class="p-desktop">
                            <div class="flex">
                                <ul class="area-column">
                                    <Area
                                        v-for="(area, a) in focus.Areas"
                                        :key="a"
                                        :area="area"
                                        :columnCount="a <= 3"
                                        :expanded="expandAreas"
                                    />
                                </ul>
                                <ul class="area-column">
                                    <Area
                                        v-for="(area, a) in focus.Areas"
                                        :key="a"
                                        :area="area"
                                        :columnCount="a > 3"
                                        :expanded="expandAreas"
                                    />
                                </ul>
                            </div>
                        </div>
                        <div class="p-mobile">
                            <div class="m-focus-area-list m-pad">
                                <ul>
                                    <Area
                                        v-for="(area, a) in focus.Areas"
                                        :key="a"
                                        :area="area"
                                        :columnCount="a <= focus.Areas.length"
                                        :expanded="expandAreas"
                                    />
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="align-c" v-if="showDownArrow">
            <img src="@/assets/Images/ArrowDownBlueFlush.png" alt="Arrow - Down" title="Arrow - Down"/>
        </div>
    </div>
</template>

<script>
import Area from "@/components/FocusCategories/Area.vue";
import AreaToggle from "@/components/FocusCategories/AreaToggle.vue";
export default {
    name: "Focus",
    props: {
        focus: Object,
        index: Number,
        totalCategories: Number
    },
    components: {
        Area,
        AreaToggle
    },
    data: () => ({
        styles: {
            backgroundColor: "",
            headingColor: "",
            copyColor: ""
        },
        classes: { even: "" },
        expandAreas: false,
        showDownArrow: false
    }),
    methods: {
        Toggle: function(expanded) { this.expandAreas = !expanded; }
    },
    beforeMount() {
        const even = (this.index + 1) % 2 == 0;
        this.styles = {
            backgroundColor: !even ? "#3d6ec4" : "#3d6ec4",
            headingColor: !even ? "#fff" : "#fff",
            copyColor: !even ? "#fff" : "#fff"
        };
        this.classes = {
            even: even ? "even" : "odd",
            topBar: this.index === 0 ? "top-bar-orange" : ""
        };
        this.showDownArrow = this.index < this.totalCategories - 1;
    }
};
</script>