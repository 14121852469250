<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Extended Learning Opportunities"
            highlight="Learning Opportunities"
        />
        <p>In order to make products more available outside of regular operational hours, we are conceptualizing a digital learning-based dashboard. This dashboard would consist of various learning-based applications developed specifically for our target audience. There are countless applications out there already that assist with multiple subjects; English, Math, Science, but we want to develop applications around results we gather while analyzing our student's preferences in learning.</p>
    </div>
    <OfferBanner
        offer="Digital Learning-based Applications"
        icon="Icons/V4/OurFocus/ProductsAndServices/DigitalLearningBasedApplications.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/DigitalLearningBasedApplicationsWatermark.png"
        :description="['<i>Learning should be accessible inside and outside of the classroom</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>From our research on digital learning, many tech-led platforms are similar in that they are too focused on allowing the user to click or drag a widget to see a result as opposed to solving a problem and then seeing the result. So to help with that, we would like to step out-of-the-box when it comes to designing these applications. We discovered that there are a large amount of tech platforms now that present themselves as an application that teaches students how to code or to write programs. However, upon doing research on common platforms such as <a href="https://scratch.mit.edu" target="_blank">Scratch</a>, <a href="https://www.tynker.com" target="_blank">Tynker</a> or Minecraft Modding, we want to create more experiences that offer step-by-step processes that teach users as they go, <span class="text-focus">exposing them to the syntax, enabling them to better understand and troubleshoot and offer opportunities for more critical thinking</span>. To sustain a level of interest in the user interface-driven features, we will utilize those as well, rendering a bit of a hybrid between a user interface and coding.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner.one" :showIcon="true"/>
    <MarketAnalysisSourceReferenceBanner backgroundColor="#fff"/>
    <ConclusionBanner :copy="conclusionBanner.two"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import MarketAnalysisSourceReferenceBanner from "@/components/Banners/SourceReference/MarketAnalysis.vue";
export default {
    name: "DigitalLearningBasedAppsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        MarketAnalysisSourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = {
            one: [
                '<h2>Research</h2>',
                '<p>We have already determined through a Market Analysis that there is a primary subset of tools being using in education such as <a href="https://scratch.mit.edu" target="_blank">Scratch</a>, <a href="https://www.tynker.com" target="_blank">Tynker</a> or Minecraft Modding. There are also web-based services such as <a href="https://code.org" target="_blank">code.org</a> that schools utilize to educate their students. However, it\'s important to note that though these provide some foundational components, it isn\'t to the extend that we are hoping and we will be furthering our analysis on the topic to validate this as well as produce more effective solutions to expose our youth to these subjects.</p>'
            ],
            two: [
                '<p>Much of the currently used interfaces and tools that teach students how to code consist of the drag-and-drop utilities that bypass the syntax of the languages being used. <span class="text-focus">We feel this is not sufficient enough and doesn\'t really qualify as "coding"</span>. We will continue to conduct reviews on additional tools as well as conceptualize and develop our own subset of tools that enable more syntax exposure, create opportunities for troubleshooting, debugging and critical thinking.</p>',
                '<p>We are also look at other educational establishments and organizations to learn how they are approaching the use of digitized learning platforms as a means of inspiration as well as determining the pain points.</p>',
                '<h2>Extended Learning</h2>',
                '<p>The benefit of creating digitized platforms is being able to continue learning outside of the classroom. This is in hopes of allowing students to catch up from lost learning time.</p>'
            ]
        };
    }
};
</script>