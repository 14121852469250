<template>
    <div class="align-c row">
        <img class="segment-arrow" src="@/assets/Images/SegmentArrow.png" alt="Segment Arrow" title="Segment Arrow"/>
    </div>
    <div id="body-introduction" class="m-pad" :style="styles.padTop">
        <div class="flex m-block narrow-content">
            <div class="m-align-c">
                <img :src="require(`@/assets/Images/Icons/V1/${icon.source}`)" :alt="`Icon - ${icon.name}`" :title="icon.name" :width="icon.width" :height="icon.height"/>
            </div>
            <div id="body-introduction-copy">
                <h1>{{ heading }}</h1>
                <p v-html="copy"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BodyIntroduction",
    props: {
        icon: Object,
        heading: String,
        copy: String,
        padTop: Boolean
    },
    data: () => ({ styles: { padTop: "" } }),
    beforeMount() {
        if (this.padTop) {
            this.styles.padTop = "padding-top: 40px";
        }
    }
};
</script>