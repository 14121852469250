<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Educators"
        copy="The pillars of our classrooms taking the front stage of bringing our youth to a better tomorrow."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="We Celebrate Teachers"
            highlight="Celebrate Teachers"
        />
        <p>Teachers are an important component of the functioning education system. Without them, the system would cease to exist. We like to celebrate the work of our educators and consider the culminating body of students that embark into our communities as <span class="text-focus">products of the powerful work these individuals do</span>.</p>
        <p>Much of our research and know-how about the ins and outs of districts, schools and classrooms is dependent on the expertise of those on the inside. We want to capitalize on this notion while empowering our teachers to further their reach in the industry.</p>
    </div>
    <div class="section-divider"></div>
    <OfferBanner
        offer="Teacher Empowerment Circle (TEC)"
        icon="Icons/V4/TEC.png"
        watermark="Icons/V4/TECWatermark.png"
        :description="['<i>Creating better solutions through the experts on the inside.</i>']"
        :height="380"
    />
    <div class="wide-content">
        <p>The <span class="text-focus">Teacher Empowerment Circle (TEC)</span> is the development and initiation of a formation of experts in the industry of education helping to drive the mission, operations and strengthen the integrity of building successful learning environments. The mission of the TEC will be aimed at ensuring we maximize our educators in not only providing their educational know-how to the organization, but spearhead specific areas of research, program execution and provide us insight to <span class="text-focus">what it takes to be successful in today's classroom</span>.</p>
        <p>Part of the work we do to ensure we understand the students we serve and the system of education itself revolves around being present within that system. There are already many experts in the industry and we would like to utilize them further to remain connected, receive important information about the academic landscape, create integrity around relationships and further bind the inside of the system to the outside where potential external entities can capitalize on the know-how coming from the classrooms of today.</p>
        <p>By building this circle and securing these relationships, we can move closer to enhancing the schooling experience with more thought-out approaches to solutions and the development of products and services that are a direct result of data and research from our own educators. <span class="text-focus">As a community, we can be stronger by binding task forces with industry experts and improve the system over time through the power of inclusion</span>.</p>
    </div>
    <ImageBanner
        name="Teacher Empowerment Circle (TEC)"
        image="TEC.png"
        watermark="TECWatermark.png"
        backgroundColor="#f2f7ff"
        :showTopBar="true"
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ImageBanner from "@/components/Banners/Image.vue";
export default {
    name: "EducatorsView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ImageBanner
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Educators",
            source: "Educators.png",
            width: 130,
            height: 130
        };
    }
};
</script>