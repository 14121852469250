<template>
    <div class="conclusion-banner">
        <div class="icon" v-if="showIcon">
            <img src="@/assets/Images/Icons/V4/ConclusionBannerCheck.png" alt="Icon - Check" title="Check" width="150"/>
        </div>
        <div class="wide-content" :style="styles.marginTop">
            <div v-for="(copyValue, c) in copy" :key="c" v-html="copyValue"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConclusionBanner",
    props: {
        copy: Array,
        showIcon: Boolean
    },
    data: () => ({ styles: { marginTop: "" } }),
    beforeMount() {
        if (!this.showIcon) {
            this.styles.marginTop = "margin-top: 50px;";
        }
    }
};
</script>