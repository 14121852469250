<template>
    <div class="section-divider" v-if="showDivider"></div>
    <div class="wide-content" v-if="data.Active">
        <div class="btn flex">
            <h2 class="btn no-margin" @click="Toggle(expanded)">{{ data.Area }}</h2>
            <img :src="indicatorSource" :alt="`Arrow - ${direction}`" :title="direction" width="30" height="30"/>
        </div>
        <div class="reference-cards flex flex-wrap m-block" v-if="expanded">
            <div class="m-span reference-card-wrap" v-for="(source, s) in data.Sources" :key="s">
                <a class="btn-card reference-card" :href="source.URL" target="_blank">
                    <div class="logo">
                        <img class="m-w-25" :src="require(`@/assets/Images/${rootImagePath}${source.Icon}`)" :alt="`Icon - ${source.Name}`" :title="source.Name"/>
                    </div>
                    <p class="align-c reference">{{ source.Name }}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReferenceCard",
    props: {
        data: Object,
        rootImagePath: String,
        index: Number
    },
    data: () => ({
        expanded: false,
        indicatorSource: "",
        direction: "",
        showDivider: true
    }),
    methods: {
        Toggle: function(expanded) {
            this.expanded = !expanded;
            this.indicatorSource = !this.expanded
                ? require("@/assets/Images/ArrowRightBlue.png")
                : require("@/assets/Images/ArrowDownBlue.png");
            this.direction = !this.expanded ? "Right" : "Down";
        }
    },
    beforeMount() {
        this.indicatorSource = require("@/assets/Images/ArrowRightBlue.png");
        this.drection = "Right";
        if (this.index === 0) {
            this.indicatorSource = require("@/assets/Images/ArrowDownBlue.png");
            this.drection = "Down";
            this.expanded = true;
            this.showDivider = false;
        }
    }
};
</script>