<template>
    <div class="m-span source-reference-banner" :style="`height: ${heightValue}; background-color: ${backgroundColor}; background-image: url(${require(`@/assets/Images/${watermark}`)});`">
        <div class="icon">
            <img :src="require(`@/assets/Images/${iconSource}`)" alt="Icon - Concept Source" title="Concept Source" width="150"/>
        </div>
        <div class="align-c wide-content">
            <h1>{{ source }}</h1>
            <div class="description">
                <p class="single" v-html="description"></p>
            </div>
            <p class="btn-blue"><a class="margin-align-c table" :href="url.destination" :target="urlTarget">{{ url.text }}</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConceptSourceBanner",
    props: {
        source: String,
        description: String,
        backgroundColor: String,
        height: String,
        url: Object,
        iconSource: String,
        watermark: String
    },
    data: () => ({
        heightValue: "",
        urlTarget: ""
    }),
    beforeMount() {
        this.heightValue = this.height !== "auto" ? `${this.height}px` : "auto";
        this.urlTarget = this.url.newWindow ? "_blank" : "_self";
    }
};
</script>