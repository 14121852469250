<template>
    <div v-if="!mobile">
        <p v-if="contact.Email !== null"><b>Email: </b><a :href="`mailto:${contact.Email}`">{{ contact.Email }}</a></p>
        <p v-if="contact.Phone !== null"><b>Phone: </b>{{ contact.Phone }}</p>
    </div>
    <div v-else>
        <p v-if="contact.Email !== null"><a :href="`mailto:${contact.Email}`">Email</a></p>
        <p v-if="contact.Phone !== null"><b>Phone: </b>{{ contact.Phone }}</p>
    </div>
</template>

<script>
export default {
    name: "ContactDetails",
    props: {
        contact: Object,
        mobile: Boolean
    }
};
</script>