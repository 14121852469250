<template>
    <div :class="['image-banner', classes.topBar]" :style="`background-color: ${backgroundColor}; background-image: url(${require(`@/assets/Images/${watermark}`)}`">
        <div class="align-c">
            <img class="m-w-50" :src="require(`@/assets/Images/${image}`)" :alt="name" :title="name"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageBanner",
    props: {
        name: String,
        image: String,
        watermark: String,
        backgroundColor: String,
        showTopBar: Boolean
    },
    data: () => ({ classes: { topBar: "" } }),
    beforeMount() {
        this.classes.topBar = this.showTopBar ? "top-bar-orange" : "";
    }
};
</script>