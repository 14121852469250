<template>
    <div id="carousel" v-if="show">
        <Slide
            v-for="(slide, s) in carousel.Slides"
            :key="s"
            :slide="slide"
            :index="s"
            :state="slideStates"
        />
        <div id="carousel-navigation" class="flex-x-center row">
            <Button
                v-for="(navigation, n) in carousel.Slides.length"
                :key="n"
                :index="n"
                :slide="GetSlide(n)"
                :state="slideStates"
                @navigate="Navigate($event)"
            />
        </div>
    </div>
</template>

<script>
import Slide from "@/components/Carousel/Slide.vue";
import Button from "@/components/Carousel/Button.vue";
import $ from "jquery";
export default {
    name: "Carousel",
    props: {
        carousel: Object,
        show: Boolean
    },
    components: {
        Slide,
        Button
    },
    data: () => ({
        slideStates: {
            selected: 0,
            previous: 0
        }
    }),
    methods: {
        GetSlide: function(slideIndex) { return this.carousel.Slides[slideIndex]; },
        Navigate: function(slide) {
            const pageData = this;
            $(".slide").fadeOut(250, function() {
                pageData.slideStates = {
                    selected: slide.index,
                    previous: slide.previous
                };
            });
        }
    }
};
</script>