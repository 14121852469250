<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Progress"
        copy="Keeping a close eye on <span class='blue-i'>educational progress</span> through the lens of our communities, schools, students and families."
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
export default {
    name: "BodyIntroductionProgress",
    components: { BodyIntroduction },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Progress",
            source: "Progress.png",
            width: 130,
            height: 130
        };
    }
};
</script>