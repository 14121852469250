<template>
    <SourceReferenceBanner
        source="Strategic Plan"
        description="View our strategic plan for 2023-2025, highlighting our goals and strategies in Education."
        backgroundColor="#fff"
        height="auto"
        :url="{
            text: 'View Plan',
            destination: 'https://believeinyou.org/FFE/Documents/Strategic Plan V3.23.pdf',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
</template>

<script>
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "CulturalAcademicAnalysisResourceBanner",
    components: { SourceReferenceBanner }
};
</script>