<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="What We Know"
            highlight="We Know"
        />
        <p>Everything we do is first determined by extensive research in the area we are focusing on. <span class="text-focus">This helps us to secure a validation for the need to produce solutions for a specific issue as well as giving us opportunities to discover other potential problems that the industry is facing</span>.</p>
        <p>We study the academic landscape, utilizing established sources in educational outcomes and dive deep into classroom conventions, content standards, student performance and much more. These are ongoing functions of the organization and we hope to create transparency regarding the findings as well as the claims to back them up.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h2"
            heading="For Better Understanding"
            highlight="Better Understanding"
        />
        <div class="cards flex flex-wrap">
            <Card
                v-for="(area, a) in Research"
                :key="a"
                :data="area"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import Research from "@/assets/JS/Cards/Research/Research.json";
export default {
    name: "ResearchView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({
        bodyIntroductionIcon: {},
        Research
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Research",
            source: "Research.png",
            width: 130,
            height: 130
        };
    }
};
</script>