<template>
    <span class="number">{{ tracker.implementing }}</span> of
    <span class="number">{{ tracker.tracking }}</span> implementations
</template>

<script>
export default {
    name: "CountLabel",
    props: { tracker: Object }
};
</script>