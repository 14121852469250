<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Change Made Possible by Believers"
            highlight="Believers"
        />
        <p>Part of our ongoing mission will be supported by partnerships, affiliations and other stakeholders. Some of our processes can be guided by or enhanced by these collaborations. We will continue to utilize these additional resources as much as possible to gain more traction within the industry.</p>
        <p>Our shared cause-focused aspirations can flourish when a binding is secured among like-minded entities; <span class="text-focus">further pushing the narrative and adding integrity to the work that we do for our community</span>.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h2"
            heading="Important Relationships"
            highlight="Relationships"
        />
        <div class="cards flex flex-wrap">
            <Card
                v-for="(focus, f) in Partners"
                :key="f"
                :data="focus"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Partners.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import Partners from "@/assets/JS/Cards/OurFocus/Partners.json";
export default {
    name: "SolutionsView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({
        bodyIntroductionIcon: {},
        Partners
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Partners",
            source: "Partners.png",
            width: 130,
            height: 130
        };
    }
};
</script>