// High-level areas of progress
var AREAS = [
    {
        area: "Products & Services",
        active: true
    },
    {
        area: "Student Outcomes",
        active: false
    },
    {
        area: "School Climate",
        active: false
    }
];

// Implementations such as Products & Services
var IMPLEMENTATIONS = {
    Content: {
        DeliveryFormat: "Product",
        Type: "Curriculum",
        Images: {
            IconImplementing: "Icons/V2/Progress/Content.png",
            IconPending: "Icons/V2/Progress/ContentPending.png",
            CardWatermark: "Icons/V2/Progress/ContentWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/ContentPendingWatermark.png"
        },
        Description: "Any curriculum, instructional or learning material that is placed in front of a student. This includes both accommodating for current standards and studying new forms of content relevancy."
    },
    "Teacher Empowerment Circle": {
        DeliveryFormat: "Service",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/TEC.png",
            IconPending: "Icons/V2/Progress/TECPending.png",
            CardWatermark: "Icons/V2/Progress/TECWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/TECPendingWatermark.png"
        },
        Description: "Creating better solutions through the experts on the inside. Our educators make the classroom possible and we are here to celebrate that."
    },
    "Digital Learning-based Applications": {
        DeliveryFormat: "Product",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/DigitalLearningBasedApplications.png",
            IconPending: "Icons/V2/Progress/DigitalLearningBasedApplicationsPending.png",
            CardWatermark: "Icons/V2/Progress/DigitalLearningBasedApplicationsWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/DigitalLearningBasedApplicationsPendingWatermark.png"
        },
        Description: "Learning should be accessible inside and outside of the classroom. A combination of user-driven apps and digital content that educates."
    },
    "Teacher Wish List": {
        DeliveryFormat: "Service",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/TeacherWishList.png",
            IconPending: "Icons/V2/Progress/TeacherWishListPending.png",
            CardWatermark: "Icons/V2/Progress/TeacherWishListWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/TeacherWishListPendingWatermark.png"
        },
        Description: "Turning the educator space into a customized learning environment. This concept is aimed at making this more possible."
    },
    "School Resource Insights Survey": {
        DeliveryFormat: "Service",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/SchoolResourceInsightsSurvey.png",
            IconPending: "Icons/V2/Progress/SchoolResourceInsightsSurveyPending.png",
            CardWatermark: "Icons/V2/Progress/SchoolResourceInsightsSurveyWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/SchoolResourceInsightsSurveyPendingWatermark.png"
        },
        Description: "A dynamic platform for identifying school needs and wants. Receiving much-needed information from multiple bodies within the system including administrators, teachers, families and students."
    },
    "Other Donations": {
        DeliveryFormat: "Service",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/OtherDonations.png",
            IconPending: "Icons/V2/Progress/OtherDonationsPending.png",
            CardWatermark: "Icons/V2/Progress/OtherDonationsWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/OtherDonationsPendingWatermark.png"
        },
        Description: "Situational support through donated gifts."
    },
    "Student Mental Health": {
        DeliveryFormat: "Service",
        Type: null,
        Images: {
            IconImplementing: "Icons/V2/Progress/StudentMentalHealth.png",
            IconPending: "Icons/V2/Progress/StudentMentalHealthPending.png",
            CardWatermark: "Icons/V2/Progress/StudentMentalHealthWatermark.png",
            CardPendingWatermark: "Icons/V2/Progress/StudentMentalHealthPendingWatermark.png"
        },
        Description: "Good mental health is a pre-requisite to performing academically and in life. As a nation, we are facing a mental health crisis and we want to dig deeper."
    }
};

// Progress; tracking, measuring of growth in any area
var PROGRESS = {

    // Assigns IDs to the Areas list
    SetAreaIDs: function() {
        let a = -1;
        for (const area in AREAS) {
            a++;
            AREAS[area].ID = a;
        }
    },

    GetTotalImplementing: function(pageData, implementations, area) {
        let totalImplementing, totalTracking, i;
        totalImplementing = 0;
        totalTracking = 0;
        const implemented = [];
        const tracked = [];
        i = -1;
        for (const implementation of implementations) {
            i++;
            const implementationName = Object.keys(IMPLEMENTATIONS)[i];
            if (implementation.Status.Implementing) {
                totalImplementing++;
                implemented.push({
                    ...implementation,
                    Name: implementationName
                });
            }
            if (implementation.Status.Tracking) {
                totalTracking++;
                tracked.push({
                    ... implementation,
                    Name: implementationName
                });
            }
        }

        // Sorting
        const sortImplemented = [];
        const sortTracked = [];
        for (const trackedImplementation of tracked) {
            if (trackedImplementation.Status.Implementing) {
                sortImplemented.push(trackedImplementation);
            } else {
                sortTracked.push(trackedImplementation);
            }
        }
        const sorted = [
            ...sortImplemented,
            ...sortTracked
        ];

        pageData.implementations = {
            area,
            tracker: {
                implementing: totalImplementing,
                tracking: totalTracking,
                none: totalImplementing === 0 && totalTracking === 0
            },
            tracked: sorted,
            implemented: implemented,
            selected: {}
        };
    },

    // Get progress area implementations from source
    GetProgressAreaImplementations: function(pageData, progressList, area) {
        const progressArea = progressList.find(p => p.Area === area);
        this.GetTotalImplementing(pageData, progressArea.Implementations, area);
    },

    Sources: {
        "Schools": [
            {
                ID: 1,
                Name: {
                    Primary: "Alliance Richard Merkin",
                    Secondary: "Middle School",
                    Full: "Alliance Richard Merkin Middle School"
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/1/Images/LogoAllianceRichardMerkinMiddleSchool.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#bebebe",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: null,
                Slogan: "Where <b>EXCEPTIONAL</b> is the <b>RULE</b>",
                Population: null,
                Website: "https://www.merkinms.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 2,
                Name: {
                    Primary: "Camino Nuevo Charter Academy - Eisner Campus",
                    Secondary: null
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/2/Images/LogoCaminoNuevoCharterAcademyEisnerCampus.jpg",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#d65f00",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: null,
                Slogan: null,
                Population: "270",
                Website: "https://eisner.caminonuevo.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: true,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 3,
                Name: {
                    Primary: "Johnnie L. Cochran Jr.",
                    Secondary: "Middle School",
                    Full: "Johnnie L. Cochran Jr. Middle School"
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/3/Images/LogoJohnnieL.CochranJr.MiddleSchool.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#18045e",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: "Cougars",
                Slogan: null,
                Population: "500+",
                Website: "https://cochrancougars.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 4,
                Name: {
                    Primary: "UCLA",
                    Secondary: null,
                    Full: "UCLA Community School"
                },
                Grades: {
                    Levels: ["TK", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    Type: "Community"
                },
                Images: {
                    Logo: "schools/4/Images/LogoUCLACommunitySchool.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#4396cc",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: null,
                Slogan: "Where we grow together",
                Population: "900+",
                Website: "https://uclacs.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: true,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 5,
                Name: {
                    Primary: "Virgil",
                    Secondary: "Middle School",
                    Full: "Virgil Middle School"
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/5/Images/LogoVirgilMiddleSchool.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#800021",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: "Tigers",
                Slogan: null,
                Population: "1100+",
                Website: "https://www.virgilms.net",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: true,
                                            Pending: false,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 6,
                Name: {
                    Primary: "Young Oak Kim Academy",
                    Secondary: null
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/6/Images/LogoYoungOakKimAcademyMiddleSchool.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#01642d",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: "Dragons",
                Slogan: "Focused on College & Career-readiness",
                Population: "800+",
                Website: "https://yokadragons.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: true,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                 
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            },
            {
                ID: 7,
                Name: {
                    Primary: "Christopher Columbus",
                    Secondary: "Middle School",
                    Full: "Christopher Columbus Middle School"
                },
                Grades: {
                    Levels: [6, 7, 8],
                    Type: "Middle"
                },
                Images: {
                    Logo: "schools/7/Images/LogoCCMS.png",
                    CardBanner: null
                },
                Colors: {
                    Primary: "#105ca3",
                    LogoBackdrop: "#fff"
                },
                StudentAlias: "Explorers",
                Slogan: null,
                Population: null,
                Website: "https://www.explorerslausd.org",
                Progress: [
                    {
                        Area: "Products & Services",
                        Implementations: [
                            {
                                Implementation: IMPLEMENTATIONS.Content,
                                Items: [
                                    {
                                        Name: "Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: true,
                                            Completed: false
                                        }
                                    },
                                    {
                                        "Name": "Introduction to Financial Literacy",
                                        Audience: "Middle School",
                                        Status: {
                                            Implementing: false,
                                            Pending: false,
                                            Completed: true
                                        }
                                    }
                                ],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Empowerment Circle"],
                                Items: [],
                                Status: {
                                    Tracking: true,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Digital Learning-based Applications"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Teacher Wish List"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["School Resource Insights Survey"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Other Donations"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            },
                            {
                                Implementation: IMPLEMENTATIONS["Student Mental Health"],
                                Items: [],
                                Status: {
                                    Tracking: false,
                                    Implementing: false,
                                    Completed: false
                                },
                                Action: "#"
                            }
                        ]
                    }
                ]
            }
        ]
    }
};

module.exports = {
    AREAS,
    IMPLEMENTATIONS,
    PROGRESS
};