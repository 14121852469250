<template>
    <div class="flex progress-bar">
        <div class="bar" v-for="(track, t) in tracking" :key="t" :style="`width: ${barWidth}; background-color: ${BarColor(t)};`"></div>
    </div>
</template>

<script>
import { CONFIG } from "@/assets/JS/Config.js";
export default {
    name: "ProgressBar",
    props: {
        implementing: Number,
        tracking: Number
    },
    data: () => ({ barWidth: 0 }),
    methods: {
        BarColor: function(trackIndex) { return trackIndex < this.implementing ? CONFIG.theme.blue : "#ccc"; }
    },
    beforeMount() { this.barWidth = 100 / this.tracking + "%"; }
};
</script>