<template>
    <div class="popup-wrap" v-if="popup.show">
        <div class="popup-overlay" @click="$emit('close')"></div>
        <div class="popup" :style="`background-image: url(${require('@/assets/Images/BGLogoWatermarkBlue.png')});`">
            <div class="flex heading">
                <h2>{{ popup.title }}</h2>
                <img class="btn flex-right" src="@/assets/Images/Close.png" alt="Close" title="Close" width="40" height="40" @click="$emit('close')"/>
            </div>
            <div class="content">
                <PopupProgressIntroduction v-if="popup.content === 'ProgressIntroduction'"/>
            </div>
        </div>
    </div>
</template>

<script>
import PopupProgressIntroduction from "@/components/Popups/Content/ProgressIntroduction.vue";
export default {
    name: "Popup",
    props: { popup: Object },
    components: { PopupProgressIntroduction },
    emits: ["close"]
};
</script>