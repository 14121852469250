<template>
    <li v-if="columnCount && expanded">
        <a :href="area.URL">{{area.Area}}</a>
    </li>
</template>

<script>
export default {
    name: "FocusArea",
    props: {
        area: Object,
        columnCount: Boolean,
        expanded: Boolean
    }
};
</script>