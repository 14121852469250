<template>
    <div :class="['card card-implementation m-span', classes.button, classes.borderTop, classes.active]">
        <div :class="['status-label', classes.statusLabel]"><span>{{ statusLabel }}</span></div>
        <div :class="['corner', classes.cornerBorder]"></div>
        <div class="btn" @click="$emit('show-implementation', data)">
            <div class="header" :style="`background-image: url(${require(`@/assets/Images/${watermarkSource}`)}`">
                <img :src="require(`@/assets/Images/${iconSource}`)" :alt="`Icon - ${data.Name}`" :title="data.Name" :width="icon.width" :height="icon.height"/>
                <p :class="['area', classes.color]">{{ data.Name }}</p>
            </div>
            <div class="description">
                <p>{{ data.Implementation.Description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { CONFIG } from "@/assets/JS/Config.js";
export default {
    name: "ImplementationCard",
    props: {
        data: Object,
        school: String,
        icon: Object
    },
    data: () => ({
        classes: {
            button: "",
            action: "",
            color: "",
            borderTop: "",
            cornerBorder: "",
            statusLabel: "",
            active: ""
        },
        action: "",
        watermarkSource: "",
        iconSource: "",
        statusLabel: ""
    }),
    methods: {
        GetStatusLabel: function() {
            let status = "";
            if (this.data.Status.Tracking) {
                status = "Tracking";
            }
            if (this.data.Status.Implementing) {
                status = "Implementing";
            } else {
                if (this.data.Status.Completed || !this.data.Status.Implementing) {
                    status = "Pending";
                }
            }
            return status;
        }
    },
    mounted() {
        const implementationAction = this.data.Action;
        const action = implementationAction !== "#";
        const implementing = this.data.Status.Implementing;
        this.classes = {
            button: action ? "btn btn-card" : "",
            action: action ? "" : "no-link",
            color: implementing ? "color-implementing" : "color-pending",
            borderTop: implementing ? "border-top-implementing" : "border-top-pending",
            cornerBorder: implementing ? "corner-border-implementing" : "corner-border-pending",
            statusLabel: implementing ? "status-label-implementing" : "status-label-pending",
            active: !CONFIG.showImplementationDetails ? "card-inactive" : ""
        };
        this.statusLabel = this.GetStatusLabel();
        if (typeof implementationAction !== "object") {
            this.action = action ? implementationAction : "";
        } else {
            if (action) {
                this.action = {
                    name: "SchoolProgressDetailImplementation",
                    params: {
                        name: this.school.replace(/ /gi, ""),
                        implementation: this.data.Name
                    }
                };
            }
        }
    },
    beforeMount() {
        const implementing = this.data.Status.Implementing;
        const images = this.data.Implementation.Images;
        this.watermarkSource = implementing
            ? images.CardWatermark
            : images.CardPendingWatermark;
        this.iconSource = implementing
            ? images.IconImplementing
            : images.IconPending;
    },
    emits: ["show-implementation"]
};
</script>