<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Like-Minded Organizations"
            highlight="Like-Minded Organizations"
        />
        <p>There is a large network of other organizations that want to provide support and well-being for our youth. We think it is not only ideal but necessary to build relationships with these sources to further drive ours missions.</p>
    </div>
    <OfferBanner
        offer="Nonprofits"
        icon="Icons/V4/OurFocus/Partners/Nonprofits.png"
        watermark="Icons/V4/OurFocus/Partners/NonprofitsWatermark.png"
        :description="['<i>Cause-aligned organizations, groups or coalitions</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>A major component to our success in delivering quality educational services to our target audience is not only going to be achieved by staying within our own confines. We want to partner with other education-focused nonprofits so that we can continue to enhance or amend our existing services. There are about just over 1,200 education-focused nonprofits in CA (~9.6% of total nonprofits, as of 2020) <i><a href="https://www.salesforce.com" target="_blank">SalesForce</a></i>* which will give us a vast landscape of resources to utilize and learn from. By sharing these resources and tools, we become more well-equipped with the processes, operations and products that are already being implemented. This also helps to eliminate the time and labor spent in creating products or services that already exist giving us more time and room to work with our internal operations and staff.</p>
        <p>Since these organizations share a similar customer base, we increase ours as well by reaching a larger audience, feeding each others missions, values and initiatives. In addition to having our own customers, by extending our reach and implementing services within other organizations, we help to unify the overall vision of providing educational assistance and other areas of support to underserved communities. <span class="text-focus">This also generates more connectedness to the community and its many educators, volunteers and other individuals that share a similar vision</span>.</p>
        <p>We also benefit from sharing a similar audience so we can utilize this to continue our mission of conducting surveys, focus groups and classroom presence, <span class="text-focus">helping us get more of a consensus on the various needs and wants in academia</span>.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner.one" :showIcon="true"/>
    <MarketAnalysisSourceReferenceBanner backgroundColor="#fff"/>
    <ConclusionBanner :copy="conclusionBanner.two"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Partners.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue"
import MarketAnalysisSourceReferenceBanner from "@/components/Banners/SourceReference/MarketAnalysis.vue";
export default {
    name: "NonprofitsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        MarketAnalysisSourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = {
            one: [
                '<h2>Research</h2>',
                '<p>As identified by the Market Analysis on nonprofit groups which highlights some of the tools and approaches to youth engagement, we benefit from understanding the functional and operational processes at these establishments, allowing us to blend into an organizational convention or mainstream routine so that transference or adoption of services can become more seamless.</p>',
                '<p>We will conduct even further analysis to review important metrics on nonprofit trends and other areas of focus such as <span class="text-focus">STEM implementation</span>, <span class="text-focus">cost</span>, <span class="text-focus">program structure</span>, <span class="text-focus">program diversity</span> among others. Our 10-year Market Analysis on nonprofit trends highlights valuable information such as social patterns, percentage of organizations implementing technology into their services, political analysis such as bills and laws and donor giving patterns. <span class="text-focus">It\'s important to understand this landscape and how it evolves or devolves over time so we can position ourselves more appropriately in all of these categories and more</span>. We will continue to conduct similar reviews going forward.</p>'
            ],
            two: [
                '<h2>Sponsorship & Collaboration</h2>',
                '<p>Situationally, we will be utilizing windows of opportunities in which we can collaborate with other nonprofit organizations that have a similar mission. There are likely to be event-specific or campaign-specific initiatives that can be influenced by a mutual relationship which produces operational support, monetary support and extending the reach of our audience(s).</p>'
            ]
        };
    }
};
</script>