<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Increasing Engagement"
            highlight="Engagement"
        />
        <p>To continue the work of finding solutions in education, we will be focusing on the development of multiple products and services that we hope will increase student outcomes and create a better learning experience. In order to capitalize on the effectiveness of these solutions, we will be looking extensively at the ongoing methods of implementation and securing methods of measuring the scope and scale of their delivery.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h2"
            heading="How We Integrate"
            highlight="Integrate"
        />
        <div class="cards flex flex-wrap">
            <Card
                v-for="(focus, f) in ProductsAndServices"
                :key="f"
                :data="focus"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
    <SourceReferenceBanner
        source="Product Roadmap"
        description="An estimated timeline of events regarding implementation of various products and services for schools."
        backgroundColor="#f2f7ff"
        height="auto"
        :url="{
            text: 'View Roadmap',
            destination: 'https://believeinyou.org/FFE/Documents/Product Roadmap.pdf?v=2.3',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import ProductsAndServices from "@/assets/JS/Cards/OurFocus/ProductsAndServices.json";
import Card from "@/components/Cards";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "SolutionsView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card,
        SourceReferenceBanner
    },
    data: () => ({ ProductsAndServices })
};
</script>