<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Learning with Boundaries"
            highlight="Learning with Boundaries"
        />
        <p>The term Content is defined by any curriculum, instructional or learning material that is placed in front of a student. Much of the public sector's content framework is adopted for many of our school districts. One such framework is the <a href="https://www.cde.ca.gov/re/cc/" target="_blank">Common Core State Standards (CCSS)</a>.</p>
        <p>The CCSS defines what students should know and be able to do in each subject in each grade. According to <a href="https://www.cde.ca.gov" target="_blank">California Department of Education</a>, the Board of Education determines this standard for their students. In 2010, more than forty states adopted the same standards for English and Math.</p>
    </div>
    <OfferBanner
        offer="Content Standards"
        icon="Icons/V4/OurFocus/Education/ContentStandards.png"
        watermark="Icons/V4/OurFocus/Education/ContentStandardsWatermark.png"
        :description="['<i>Standardizing a nation of learners</i>']"
        :height="380"
    />
    <div class="wide-content">
        <h2>Too Many Boundaries</h2>
        <p>Defining a standard that can be adopted by a multitude of schools creates structure and routine. However, a system such as this means that there are many boundaries in place for each subject that prevents further learning or expression. Standards like these add a set of guidelines for every student that they must follow in order to be considered competent in their coursework. <span class="text-focus">However, the guidelines themselves are far too narrow and parochial to enable creativity</span>.</p>
        <p>Consider this in just a twenty-student classroom, a standard of learning that all students have to follow. It's likely that five of them, or even just one of them, will not adhere well to these standards due to differing learning styles. The CCSS says that not only do we have to learn within narrow boundaries but we also have to follow a singular path to getting to the right answer. The real world encourages us to explore several paths. The reality is we are applying the a standard across a nation that will not fit the mold of every classroom. As a nation, we are restricting learning, eliminating creativity and discouraging exploration. <span class="text-focus">This means that public schools cannot be places of creativity and innovation which is detrimental to the outcomes of our students</span>.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "ContentStandardsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: [] }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Research &amp; Understanding</h2>',
            '<p>Understanding the existing established content framework is key. In addition, understanding the scope and scale in which its subject reaches students and for which grade levels is equally important. As an organization interested in creating content that benefits our youth, we will be studying and analyzing the subject areas being taught today in these standards and the expected outcomes from the student body. To refine our research in this area, we will be harnessing partnerships, the <a href="/Educators">Teacher Empowerment Circle (TEC)</a>, the <a href="https://www.cde.ca.gov/" target="_blank">California Department of Education</a> (for local data) and other sources to give us more insight into what this looks like in schools and classrooms.</p>'
        ];
    }
};
</script>