<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Classrooms with Heart"
            highlight="Classrooms with Heart"
        />
        <p>Our educators needs the freedom to customize their classrooms and create a welcoming, safe space for their students. With a tailored environment, we build the foundation for improved cultures and <span class="text-focus">invoke the desire to teach and to learn</span>.</p>
    </div>
    <OfferBanner
        offer="Teacher Wish List"
        icon="Icons/V4/OurFocus/ProductsAndServices/TeacherWishList.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/TeacherWishListWatermark.png"
        :description="['<i>Turning the educator space into a customized learning environment</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>The <span class="text-focus">Teacher Wish List</span> is an ongoing list of inquiries and requests submitted by teachers that may be fulfilled to accommodate them for purchasing their own classroom resources. <span class="text-focus">It's essential that teachers can build and customize their own learning environments for their students</span> but they can't always do so with the lack of funding. This concept is aimed to enable more teachers to personalize classrooms.</p>
        <h2>Teacher Shortage</h2>
        <p>There is an ongoing <router-link to="/OurFocus/Education/TeacherShortage">teacher shortage</router-link> that is becoming a rising crisis; teachers are walking away from the profession due to feeling like they are not getting the respect they deserve, feelings of burnout or because of financial instability. Statistics indicate that a third of new teachers leave within three years <i><a href="https://edsource.org" target="_blank">EdSource</a> as of January 2022</i>* <span class="text-focus">Teacher retention is critical to master as longevity in the classroom is an important parameter when considering student-to-teacher camaraderie</span>. Not only are teachers leaving the profession but there has been reports of a lesser amount of youth wanting to get into that industry.</p>
        <h2>A Better Classroom</h2>
        <p>The concept of enabling more teachers to receive free resources for their classrooms produces opportunities where the teacher can create a space for themselves and their students and in turn boosts the culture and comfort of the learning environment. It's cultures like these that might alter an educators perception on how out-of-place they may feel, <span class="text-focus">increasing retention and creating that much-needed longevity</span>.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
export default {
    name: "TeacherWishListFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>School Engagement</h2>',
            '<p>The best way to support this concept is to continue engaging with schools and more specifically their Parent Centers. These centers are the best channels to connect with both the school body and the families of the students. Through here, we can connect directly to teachers and provide introductions to this concept. The Parent Center also helps with school-wide communication regarding specific inquiries, involving the entire educator staff to participate.</p>',
            '<h2>School Guidelines</h2>',
            '<p>Guidelines or Statements of Understanding need to be developed that communicates to the school and educator(s) of the level of frequency of donations as well as the types of donations that are eligible. As an organization, we must stay mission-aligned and we need to create a structure that allows us to compliment the mission with our donations.</p>',
            '<p>Further, we understand there is a budget plan for every school, whether that\'s Federal, State, <a href="https://www.cde.ca.gov/fg/aa/lc/" target="_blank">Local Control Funding Formula (LCFF)</a>, <a href="https://www.cde.ca.gov/re/lc/" target="_blank">Local Control and Accountability Plan (LCAP)</a>, COVID Relief funding or others, that have their own qualification dates and/or depletions. Taking these into account provides us with windows of opportunities to secure donations to fill a void in budget restraints.</p>',
            '<p>We will be utilizing our partners to secure opportunities to increase amount and frequency of donations in addition to direct donations from the organization.</p>'
        ];
    }
};
</script>