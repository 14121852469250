<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Some of Our Findings"
            highlight="Our Findings"
        />
        <p>This is a collection of information regarding the industry of education; information that we analyze, study and put to practice. This list is no exhaustive and will continue to grow as we persist on the idea that we can't produce effective outcomes for our mission if we do not dig deep into the workings of our craft.</p>
        <div class="cards flex flex-wrap">
            <Card
                v-for="(area, a) in Hub"
                :key="a"
                :data="area"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Research.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import Hub from "@/assets/JS/Cards/Research/Hub.json";
export default {
    name: "ResearchHubView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({ Hub })
};
</script>