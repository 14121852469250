<template>
    <div class="card-progress-overview-wrap m-span">
        <div class="card-progress-overview" :style="`border-top: 10px solid ${data.Colors.Primary}; background-image: url(${bannerImage});`">
            <div class="details">
                <div class="corner" :style="styles.cornerBorder"></div>
                <div class="brand flex">
                    <img class="m-w-10" :src="require(`@/${data.Images.Logo}`)" :alt="`Icon - ${fullName}`" :title="fullName" :style="`border: 2px solid ${data.Colors.Primary}; background-color: ${data.Colors.LogoBackdrop}`"/>
                </div>
                <div class="heading">
                    <h1><a :href="data.Website" target="_blank">{{ data.Name.Primary }}</a></h1>
                    <h2>{{ data.Grades.Type }} School</h2>
                    <div class="single sub-details">
                        <p class="student-alias">{{ studentAlias }}</p>
                        <p><b>Grades:</b> {{ gradeLevels }}</p>
                    </div>
                </div>
            </div>
            <ImplementationTracker
                :tracker="implementations.tracker"
                backgroundColor="#fcfdfe"
                borderTopColor="#e3e3e3"
                location="Top"
            />
            <div class="button">
                <router-link :to="progressRoute">View Progress</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ImplementationTracker from "@/components/Progress/Schools/Implementations/Tracker/";
import { PROGRESS } from "@/assets/JS/Progress/Progress.js";
export default {
    name: "ProgressProductsAndServices",
    props: {
        data: Object,
        index: Number
    },
    components: { ImplementationTracker },
    data: () => ({
        fullName: "",
        implementations: {
            tracker: {
                implementing: 0,
                tracking: 0,
                none: false
            }
        },
        bannerImage: "",
        styles: { cornerBorder: "" },
        studentAlias: "",
        progressRoute: {},
        gradeLevels: ""
    }),
    methods: {
        GetGradeLevels: function() {
            const levels = this.data.Grades.Levels;
            this.gradeLevels = `${levels[0]}-${levels[levels.length - 1]}`;
        },
        ViewDetail: function(id) {
            this.view.overview = false;
            this.view.detail = true;
            this.detailID = id;
        }
    },
    beforeMount() {
        const schoolName = this.data.Name;
        this.fullName = `${schoolName.Primary} ${schoolName.Secondary !== null ? schoolName.Secondary : ""}`;

        // Get progress area implementations
        PROGRESS.GetProgressAreaImplementations(this, this.data.Progress, "Products & Services");

        this.GetGradeLevels();
        this.bannerImage = require("@/assets/Images/BGLogoWatermarkBlue.png");
        this.styles.cornerBorder = `border-bottom: 25px solid ${this.data.Colors.Primary}`;
        this.studentAlias = this.data.StudentAlias !== null ? `Go ${this.data.StudentAlias}!` : "";

        // Set school route
        if (schoolName.Full === undefined) {
            schoolName.Full = schoolName.Primary;
        }
        this.progressRoute = {
            name: "SchoolProgressDetail",
            params: { name: schoolName.Full.replace(/ /gi, "") }
        };

        this.gradeLevels = `${this.data.Grades.Levels[0]}-${this.data.Grades.Levels[this.data.Grades.Levels.length - 1]}`;
    }
};
</script>