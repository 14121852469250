<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="What We Do"
        copy="Through our services, we hope to create, influence and expand academic opportunities for youth in our communities, improving the overall school experience."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="The Journey"
            highlight="Journey"
        />
        <p>As part of our journey, we are continuously building and expanding on various services and products to reach our mission.</p>
        <p>Much of our work entails conducting the necessary research to produce solutions for our schools, its educators, families and students. Building a better classroom means being present and understanding the many functions that make a good quality learning environment.</p>
        <p>Beyond the functional space of the classroom, we also care about what the student receives for academic content. Through constant research and analysis, we aim to bring more relevancy to what students are learning while aligning to district guidelines and content standard frameworks.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <MultiColorHeader
            type="h2"
            heading="More About What We Do"
            highlight="What We Do"
        />
        <div class="cards flex flex-wrap">
            <Card
                v-for="(area, a) in WhatWeDo"
                :key="a"
                :data="area"
                :icon="{ width: 200, height: 200 }"
            />
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import Card from "@/components/Cards";
import WhatWeDo from "@/assets/JS/Cards/WhatWeDo.json";
export default {
    name: "WhatWeDoView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        Card
    },
    data: () => ({
        bodyIntroductionIcon: {},
        WhatWeDo
    }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "What We Do",
            source: "WhatWeDo.png",
            width: 130,
            height: 130
        };
    }
};
</script>