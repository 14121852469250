<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Donate"
        copy="There are many ways to invest in our youth. Join us on the journey to creating a better future."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="The Greatest Investment"
            highlight="Greatest Investment"
        />
        <p>Thank you for taking the time to learn more about our efforts. Considering you have taken the time to explore this with us, <span class="text-focus">you have already been a contributor</span>.</p>
        <p>Currently, we are establishing relationships and engaging with multiple schools in Koreatown, Los Angeles. As we build these relationships and create new ones, further analyzing and understanding the landscape of academics and its students, we have begun the process of implementing multiple solutions. Some of which includes programming, donations and conducting review and analysis of school and student outcomes.</p>
        <p><span class="text-focus">Invest with us today and help us create better learning experiences for our youth and for our community</span>.</p>
    </div>
    <div class="section-divider"></div>
    <h2 class="align-c">Join us and <b><i>invest</i></b> in...</h2>

    <!-- Mobile -->
    <div class="p-desktop">
        <div class="icon-banner">
            <div class="icon">
                <img src="@/assets/Images/Icons/V5/Heart.png" alt="Icon - Heart" title="Heart"/>
            </div>
            <div class="donate-recipient-split"></div>
            <div class="donate-track flex-x-center wide-content">
                <div class="donate-recipient">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Schools.png" alt="Icon - Schools" title="Schools"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Schools</p>
                        <p>For <b><i>education</i></b></p>
                    </div>
                </div>
                <div class="arrow">
                    <img src="@/assets/Images/ArrowRightWhite.png" alt="Arrow" title="Arrow" width="70"/>
                </div>
                <div class="donate-recipient">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Students.png" alt="Icon - Students" title="Students"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Students</p>
                        <p>For <b><i>youth</i></b></p>
                    </div>
                </div>
                <div class="arrow">
                    <img src="@/assets/Images/ArrowRightWhite.png" alt="Arrow" title="Arrow" width="70"/>
                </div>
                <div class="donate-recipient">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Community.png" alt="Icon - Community" title="Community"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Community</p>
                        <p>For <b><i>us</i></b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mobile">
        <div class="icon-banner">
            <div class="icon">
                <img src="@/assets/Images/Icons/V5/Heart.png" alt="Icon - Heart" title="Heart"/>
            </div>
            <div class="m-donate-recipient-split"></div>
            <div class="donate-track flex-x-center m-block m-donate-track wide-content">
                <div class="donate-recipient m-span">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Schools.png" alt="Icon - Schools" title="Schools"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Schools</p>
                        <p>For <b><i>education</i></b></p>
                    </div>
                </div>
                <div class="arrow">
                    <img src="@/assets/Images/ArrowDownWhite.png" alt="Arrow" title="Arrow" width="70"/>
                </div>
                <div class="donate-recipient m-span">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Students.png" alt="Icon - Students" title="Students"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Students</p>
                        <p>For <b><i>youth</i></b></p>
                    </div>
                </div>
                <div class="arrow">
                    <img src="@/assets/Images/ArrowDownWhite.png" alt="Arrow" title="Arrow" width="70"/>
                </div>
                <div class="donate-recipient m-span">
                    <div class="donate-recipient-icon">
                        <img src="@/assets/Images/Icons/V3/Donate/Community.png" alt="Icon - Community" title="Community"/>
                    </div>
                    <div class="align-c labels single">
                        <p>Community</p>
                        <p>For <b><i>us</i></b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="wide-content">
        <div class="donate-callout">
            <DonateCallout/>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import DonateCallout from "@/components/DonateCallout.vue";
export default {
    name: "DonateView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        DonateCallout
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Donate",
            source: "Donate.png",
            width: 130,
            height: 130
        };
    }
};
</script>