<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="About Us"
        copy="We are a team of believers that are invested in the well-being of our youth and strive to see that good change can happen during their academic journeys."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="About Us"
            highlight="Us"
        />
        <p><span class="text-focus">Future Forward Education</span> is a nonprofit organization that was established to create better solutions for youth schools and other learning environments so that our youth have a greater chance of success and entering the world prepared.</p>
    </div>
    <div class="section-divider"></div>
    <div class="wide-content">
        <div class="flex icon-text m-block">
            <div class="m-align-c">
                <img class="m-w-25 m-h-25" src="@/assets/Images/Icons/V2/Content.png" alt="Icon - Content" title="Content" width="130" height="130"/>
            </div>
            <div>
                <h2>We Know</h2>
                <p><a href="https://www.lausd.org/domain/4" target="_blank">Los Angeles Unified School District (LAUSD)</a> students struggle to meet grade-level standards in Math and English and we are standing up to turn that struggle into more than meeting a grade-level standard.</p>
                <p>As believers in the power of youth, we take our commitment seriously.</p>
                <h2>How We Do This</h2>
                <p>We conduct ongoing research regarding the state of academics, student achievement, demographics and more from our local schools using up-to-date data sets that districts rely such as LAUSD's <a href="https://www.cde.ca.gov/ta/tg/sa/sbacinterimassess.asp" target="_blank">Smarter Balanced Assessment Criteria (SBAC)</a>, <a href="https://www.webudgetla.org" target="_blank">WeBudget LA</a>, <a href="https://www.caschooldashboard.org" target="_blank">California Dashboard</a> and others, as well as collecting our own directly from the source — the schools and student body.</p>
                <p>Our research helps us to establish the needs of the community and for our students. We already know the importance of access to STEM resources, so we're developing content that can move students beyond grade-level standards.</p>
                <p>We build relationships with schools as an essential piece of the research so that we can more effectively develop solutions for students. It is not just about reaching the student and determining their needs, it's about the system that inherits the student. By making a system-level approach, <span class="text-focus">we can offer more to every student and on a greater scale</span>.</p>
            </div>
        </div>
        <div class="flex icon-text m-block">
            <div class="m-align-c">
                <img class="m-w-25 m-h-25" src="@/assets/Images/Icons/V2/3Group.png" alt="Icon - Whole Family Approach" title="Whole Family Approach" width="130" height="130"/>
            </div>
            <div>
                <h2>The Whole Family Approach</h2>
                <p>We are here to offer solutions to our youth through educational opportunities but we understand the importance of community and family. Every student is part of the larger community and we take a family-level approach to finding and creating solutions through increased access to resources, programming, content and more. By focusing on the whole family, <span class="text-focus">we can make more impact in the schools, the classroom, the student and their families</span>.</p>
            </div>
        </div>
    </div>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
export default {
    name: "AboutView",
    components: {
        BodyIntroduction,
        MultiColorHeader
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "About Us",
            source: "AboutUs.png",
            width: 130,
            height: 130
        };
    }
};
</script>