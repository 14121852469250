<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="The Needs &amp; Wants of Education"
            highlight="The Needs &amp; Wants of Education"
        />
        <p>Establishing ongoing insights into the needs and wants of our schools is crucial to being able to generate better solutions for our schools, districts, students and their families. Collecting this information over time helps us understand the types of gaps that exist in the system as well as the types of requests that may have otherwise been left to the side; anything from programming to events, items for the school or anything that would benefit the culture of education.</p>
    </div>
    <OfferBanner
        offer="School Resource Insights Survey"
        icon="Icons/V4/OurFocus/ProductsAndServices/SchoolResourceInsightsSurvey.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/SchoolResourceInsightsSurveyWatermark.png"
        :description="['<i>A dynamic platform for identifying school needs and wants</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>The <span class="text-focus">School Resource Insights Survey</span> is a dynamic platform (physical and digital) that offers us important information about the needs and wants of a school establishment. Differing from the <router-link to="/OurFocus/ProductsAndServices/TeacherWishList">Teacher Wish List</router-link>, this is a broader channel that is designed to receive information from multiple bodies within the system including administrators, <span class="text-focus">teachers</span>, <span class="text-focus">students</span> and their <span class="text-focus">families</span>. The initial metrics collected are general resource insights which could be items for the school or classroom, planned trips for students or programming. However, from this initial pull, the survey is meant to react and respond to requests for each school in which it becomes school-specific and on a quarterly or semeseterly basis. There does need to be defined accountability and deadlines for submittal. Though it is not designed to be taken down, in order for communities to benefit from the results, its survival relies on incremental interactions.</p>
        <p>Having ongoing reporting of these metrics is not only important to fulfilling insights found at a particular establishment but we do want to utilize this data to analyze and further create solutions as well as create a transparency around the datasets so that it can be shared with the city for even more analysis and on a wider scale.</p>
    </div>
    <SourceReferenceBanner
        source="School Resource Insights Survey"
        description="A dynamic platform that offers us important information about the needs and wants of school establishments. It's designed to enable us to expand services and resources as requested directly from our community."
        backgroundColor="#fff"
        height="auto"
        :url="{
            text: 'View Concept',
            destination: 'https://insights.futureforwardeducation.org/Main',
            newWindow: true
        }"
        iconSource="Icons/V4/ConceptSource.png"
        watermark="Icons/V4/ConceptSourceWatermark.png"
    />
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
export default {
    name: "SchoolResourceInsightsSurveyFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        SourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>School Engagement</h2>',
            '<p>Much like the <a href="/OurFocus/ProductsAndServices/TeacherWishList">Teacher Wish List</a> concept, we will need to be heavily integrated within the system and working closely with school officials to establish methods for distributing and moderating the use of this survey initiative. This includes having discussions around school and user accountability in that a project like this only generates results with consistent use.</p>',
            '<p>More discussions will involve the results themselves, how they are analyzed and redirected including brainstorming of best practices for tying a result set directly to an inquiry.</p>',
            '<h2>Regulation Adherence</h2>',
            '<p>The handling of data in and around academics in our community will be supported by regulations and school/district data-sharing guidelines. Information being gathered does not contain any protective personal information and regulatory functions involve creating the verbiage explaining the reasons for the data collections and where it will go.</p>',
            '<h2>Research</h2>',
            '<p>Once data is collected from the schools and the community, we begin studying and analyzing the results utilizing Data Scientists and Qualitative Researchers. The nature and context of this type of data and the large array of metrics being gathered, gives us many exciting ways in which we can query and pull result sets for a specific resource, location, school and more.</p>',
            '<p>Capitalizing on the various types of users, we want to be able to understand the relationship between the inquiry and the user, whether that be a teacher or a student, and seek out patterns such as high-frequency inquiries or in contrast, a unique inquiry for a specific school, <span class="text-focus">all of which offers us insights into delivering resources or finding new avenues to explore</span>.</p>'
        ];
    }
};
</script>