<template>
    <div class="flex tabs">
        <Tab
            v-for="(tab, t) in tabs"
            :key="t"
            :tab="tab"
            :index="t"
            :selectedIndex="selectedTab.index"
            @select-tab="$emit('select-tab', $event)"
        />
    </div>
</template>

<script>
import Tab from "@/components/Navigations/Tabs/Tab.vue";
export default {
    name: "TabNavigationTab",
    props: {
        tabs: Object,
        selectedTab: Object
    },
    components: { Tab },
    emits: ["select-tab"]
};
</script>