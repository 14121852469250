<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="Additional Gifts"
            highlight="Gifts"
        />
        <p>We want to be able to provide more resources to schools to create a better environment for both learning and teaching. We are hoping to fulfill additional requests not discovered through the <router-link to="/OurFocus/ProductsAndServices/TeacherWishList">Teacher Wish List</router-link> and the <router-link to="/OurFocus/ProductsAndServices/SchoolResourceInsightsSurvey">School Resources Insights Survey</router-link>.</p>
    </div>
    <OfferBanner
        offer="Other Donations"
        icon="Icons/V4/OurFocus/ProductsAndServices/OtherDonations.png"
        watermark="Icons/V4/OurFocus/ProductsAndServices/OtherDonationsWatermark.png"
        :description="['<i>Situational support through donated gifts</i>']"
        :height="410"
    />
    <div class="wide-content">
        <p>These types of donations might be organization-defined resources, specific inquiries for events, or any other resource that would remain aligned with the mission of creating better learning environments and schools and that were not brought forth through any of the other services we offer that detail donation requests.</p>
    </div>
    <ConclusionBanner :copy="conclusionBanner" :showIcon="true"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/ProductsAndServices.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue"
export default {
    name: "OtherDonationsFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = [
            '<h2>Delivery</h2>',
            '<p>Much like any of our other services that involve making donations, we will be developing routines for receiving requests and the delivery of the item(s). To ensure successful transactions, we plan on creating processes that allow us to confirm the intended donation arrives in the correct location and with the correct source. We will be utilizing our partners to secure opportunities to increase amount and frequency of donations in addition to direct donations from the organization.</p>'
        ];
    }
};
</script>