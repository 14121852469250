<template>
    <ul :class="['sub-navigation', classes.subNavigation]" v-show="subNavigationItems.length && show">
        <li v-for="(subNavigation, s) in subNavigationItems" :key="s">
            <router-link :to="subNavigation.Route.Route">{{ subNavigation.Name }}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: "SubNavigation",
    props: {
        subNavigationItems: Array,
        show: Boolean,
        mobile: Boolean
    },
    data: () => ({
        classes: { subNavigation: "" },
        navigationItems: []
    }),
    beforeMount() { this.classes.subNavigation = this.mobile ? "m-sub-navigation" : ""; }
};
</script>