<template>
    <BodyIntroduction/>
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="A Scarcity We Cannot Afford"
            highlight="A Scarcity We Cannot Afford"
        />
        <p>We are not seeing enough opportunities in STEM (Science, Technology, Engineering & Math) for our youth in our schools. We live in a very tech-driven society and that tech is evolving very fast. It is essential that more opportunities exist where such materials are made available and are being taught so students can be better prepared moving forward.</p>
    </div>
    <OfferBanner
        offer="Inadequate STEM Learning"
        icon="Icons/V4/OurFocus/Education/InadequateSTEMLearning.png"
        watermark="Icons/V4/OurFocus/Education/InadequateSTEMLearningWatermark.png"
        :description="['<i>Fundamentals are lacking in the classroom</i>']"
        :height="380"
    />
    <div class="wide-content">
        <h2>Some of Our Findings</h2>
        <p>Our findings show that ~5% of education-focused nonprofits targeting youth in adverse communities implement STEM learning in their programs in Southern California as of 2018 Candid <i>GuideStar</i>*. That's just referring to services offered for free. Another point to make in regards to tech learning is that certain fundamentals are not being taught. As part of the evolving materials as mentioned earlier, it isn't always the case that educational establishments evolve and react to modern-day trends, whether that be through products, software or methodologies being used in instructional materials.</p>
        <p>Further analysis shows us that 10% of STEM scholars in higher education consist of minority groups or Underrepresented Minority Groups (URMs), including women, black/African Americas, Latina/o/x, and Native American/Alaska natives. In particular, women of color earn fewer than 5% of STEM bachelor's degrees in the United States. Some gains have been made in areas such as psychology, health sciences, biology, and the social sciences, however, <span class="text-focus">there are still significant gaps characterized by stagnated growth in areas including physics, computer science, and engineering</span>. This gap has been attributed to the drop off in interest that occurs during students' elementary and middle school years.</p>
        <p>According to the <i>Pew Research Center (2021)</i>*, people of color remain underrepresented in STEM careers, despite gains that have been made over the course of the last decade. Additionally, their research shows that <span class="text-focus">there is also a wage gap (74%) that exists for Black and Hispanic women that is even more severe than the general labor marketwide gender pay gap (80%)</span>.</p>
        <p>Our Market Analysis identifies nonprofit groups that also heavily rely on these same STEM tools such as <a href="https://lamakerspace.org" target="_blank">LA Makerspace</a>, <a href="https://wearebgc.org" target="_blank">Black Girls Code</a> (as of 2020), <a href="https://i2learning.org" target="_blank">i2 Learning</a> (as of 2020), KitHub and more. However, the same potential issue arises; more effective solutions that grant greater exposure to key concepts in STEM may be lacking at these sources. The analysis also highlights what the trends are for STEM education among nonprofits in the past 10 years.</p>
    </div>
    <div class="columns-2 flex-x-center m-block source-reference-banners">
        <MarketAnalysisSourceReferenceBanner backgroundColor="#f2f7ff"/>
        <CulturalAnalysisSourceReferenceBanner backgroundColor="#f2f7ff"/>
    </div>
    <ConclusionBanner :copy="conclusionBanner.one" :showIcon="true"/>
    <SourceReferenceBanner
        source="Survey Results"
        description="A high-level STEM-related survey taken by middle school students in the area of <span class='blue-i'>Digital Art &amp; Design</span> and <span class='blue-i'>Programming or Coding</span>."
        backgroundColor="#fff"
        height="auto"
        :url="{
            text: 'View Results',
            destination: 'https://believeinyou.org/FFE/Documents/Survey Results - STEAM Learning.pdf?v=2',
            newWindow: true
        }"
        iconSource="Icons/V4/ExternalSource.png"
        watermark="Icons/V4/ExternalSourceWatermark.png"
    />
    <ConclusionBanner :copy="conclusionBanner.two"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction/Education.vue";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import OfferBanner from "@/components/Banners/Offer.vue";
import ConclusionBanner from "@/components/Banners/Conclusion.vue";
import SourceReferenceBanner from "@/components/Banners/SourceReference";
import MarketAnalysisSourceReferenceBanner from "@/components/Banners/SourceReference/MarketAnalysis.vue";
import CulturalAnalysisSourceReferenceBanner from "@/components/Banners/SourceReference/CulturalAcademicAnalysis.vue";
export default {
    name: "InadequateSTEMLearningFocusView",
    components: {
        BodyIntroduction,
        MultiColorHeader,
        OfferBanner,
        ConclusionBanner,
        SourceReferenceBanner,
        MarketAnalysisSourceReferenceBanner,
        CulturalAnalysisSourceReferenceBanner
    },
    data: () => ({ conclusionBanner: {} }),
    beforeMount() {
        this.conclusionBanner = {
            one: [
                '<h2>Research</h2>',
                '<p>In order to begin developing opportunities for more STEM access or creating STEM content, we did a Market Analysis to see what the trends are for STEM education among nonprofits in the past 10 years. We also did a Cultural and Academic Analysis that highlighted some demographics and access to technology in education.</p>',
                '<p>Ongoing research includes understanding the current software, tools and devices being used today to teach and learn from. We already know that the more popular tools are <a href="https://scratch.mit.edu" target="_blank">Scratch</a>, <a href="https://www.tynker.com" target="_blank">Tynker</a> and Minecraft Modding but we will continue looking at these sources as well as the organizations that are using them.</p>',
                '<p>We will continue to conduct Market Landscape evaluations to better understand the nonprofit sector and what other establishments are delivering in terms of their STEM programming and what their stakeholder and affiliated relationships entail. In addition to this, we will conduct surveys and focus groups to gather additional data to track STEM necessity, student performance and intrigue in STEM subjects. This allows us to determine our structural position within these sectors based on multiple metrics such as financial, products, services and others.</p>',
                '<h2>Digging Deeper</h2>',
                '<p>For further review, we have already begun conducting surveys and reviewing feedback. We consider this to be a good benchmark to start implementing content that caters to our audience. One of our STEM high-level surveys conducted at a local middle school shows us that <span class="text-focus">in the area of Digital Art & Design, there was a 74% interest rate (only 4% showing no interest)</span> and <span class="text-focus">in the area of Programming or Coding, there was a 58% interest rate (only 6% showing no interest)</span> telling us that a majority of these individuals express interest in these subjects.</p>',
                '<p>Upon these results validating our assessment on limited access to STEM materials (especially in tech) for underserved youth, we want to continue to create surveys with these same metrics in mind and amass enough results to provide services to a larger audience.</p>'
            ],
            two: [
                '<p>The level of interest in Programming or Coding shows a small drop in interest compared to Digital Art and Design but as more analysis of results shows, this is also due to lack of knowledge in the subjects and further validates the need to offer more exposure to these types of resources.</p>',
                '<h2>Increasing STEM Access</h2>',
                '<p>Using data from our surveys adds emphasis to various subjects in STEM so we can start to design content based off the collected results and create interactive learning cultures with these tools and resources in mind.</p>',
                '<p>Digging more into the fundamentals. Our research shows that there are a few aspects of the subject matter that are not being distributed enough such as <span class="text-focus">teamwork collaboration</span>, the <span class="text-focus">processes of breaking down and troubleshooting problems</span>, <span class="text-focus">critical thinking exercises</span> and the <span class="text-focus">use of project-driven lessons versus full instructor-led courses</span>.</p>',
                '<p>Data collection is only a starting point to building effective content. We will need to rely on the subject matter experts whom have the experience teaching and implementing real-world applications that are both understandable and relevant to the students. Collaboration and networking with other individuals familiar with STEM projects and content is important to the delivery.</p>'
            ]
        };
    }
};
</script>